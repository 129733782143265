var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-person"},[_c('validation-observer',{ref:"observer"},[_c('v-row',{staticClass:"mb-2"},[_c('v-card-title',{staticClass:"text--text"},[_vm._v(" Контактна особа ")])],1),_c('v-row',{staticClass:"mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-md-2",attrs:{"lg":"4","md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Прізвище*","rules":("required|min:" + _vm.FMLMinLength + "|alphaDash|max:" + _vm.FMLMaxLength + "\n          |isInvalid:" + (_vm.invalidFields.last_name))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
          var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Прізвище*","error-messages":errors,"error":!!_vm.invalidFields.last_name,"success":valid && _vm.lastName !== ''
              && !_vm.invalidFields.last_name},on:{"input":function($event){validate($event); _vm.removeFromInvalid('lastName')},"keyup":function($event){return _vm.capitalizeFirstLetter('lastName')}},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}])})],1),_c('v-col',{staticClass:"pl-md-2 pr-lg-2",attrs:{"lg":"4","md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Ім'я*","rules":("required|min:" + _vm.FMLMinLength + "|alphaDash|max:" + _vm.FMLMaxLength + "\n          |isInvalid:" + (_vm.invalidFields.first_name))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
          var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Ім'я*","error-messages":errors,"error":!!_vm.invalidFields.first_name,"success":valid && _vm.firstName !== ''
              && !_vm.invalidFields.first_name},on:{"input":function($event){validate($event); _vm.removeFromInvalid('firstName')},"keyup":function($event){return _vm.capitalizeFirstLetter('firstName')}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}])})],1),_c('v-col',{staticClass:"pl-lg-2",attrs:{"lg":"4","cols":"12"}},[_c('validation-provider',{attrs:{"name":"По батькові","rules":("required|min:" + _vm.FMLMinLength + "|alphaDash|max:" + _vm.FMLMaxLength + "\n          |isInvalid:" + (_vm.invalidFields.mid_name))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
          var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"По батькові*","error-messages":errors,"error":!!_vm.invalidFields.mid_name,"success":valid && _vm.midName !== ''
              && !_vm.invalidFields.mid_name},on:{"input":function($event){validate($event); _vm.removeFromInvalid('midName')},"keyup":function($event){return _vm.capitalizeFirstLetter('midName')}},model:{value:(_vm.midName),callback:function ($$v) {_vm.midName=$$v},expression:"midName"}})]}}])})],1),_c('v-col',{staticClass:"pr-md-2",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Телефон*","rules":("required|uaPhone|isInvalid:" + (_vm.invalidFields.mobile_number))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var validate = ref.validate;
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('+380 (##) ###-##-##'),expression:"'+380 (##) ###-##-##'"}],ref:"mobileNumber",attrs:{"placeholder":"+380 (99) 999-99-99","dense":"","filled":"","rounded":"","outlined":"","type":"tel","color":"text","label":"Телефон*","error-messages":errors,"error":!!_vm.invalidFields.mobile_number,"success":valid && _vm.mobileNumber.length === 19
              && !_vm.invalidFields.mobile_number},on:{"input":function($event){validate($event); _vm.removeFromInvalid('mobileNumber');}},model:{value:(_vm.mobileNumber),callback:function ($$v) {_vm.mobileNumber=$$v},expression:"mobileNumber"}})]}}])})],1),_c('v-col',{staticClass:"pl-md-2",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Ким доводиться клієнту*","rules":("required|isInvalid:" + (_vm.invalidFields.contact_type_id))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
              var validated = ref.validated;
return [_c('v-select',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","color":"text","item-color":"text","label":"Ким доводиться клієнту*","error-messages":errors,"item-text":"value","item-value":"silver_id","items":_vm.referenceBooks('contact_person_type').length > 0 ?
              _vm.referenceBooks('contact_person_type') : _vm.defaultDataForSelect,"menu-props":{ offsetY: false },"error":!!_vm.invalidFields.contact_type_id || (validated && _vm.contactTypeId === 0),"success":valid && !!_vm.contactTypeId && !_vm.invalidFields.contact_type_id},on:{"change":function($event){return _vm.removeFromInvalid('contactTypeId')}},model:{value:(_vm.contactTypeId),callback:function ($$v) {_vm.contactTypeId=$$v},expression:"contactTypeId"}})]}}])})],1)],1),_c('v-row',{staticClass:"ma-0 mb-4 flex-nowrap"},[_c('validation-provider',{staticClass:"position-relative",attrs:{"name":"checkbox","rules":("checkboxRequired|isInvalid:" + (_vm.invalidFields.is_not_pzo))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var validated = ref.validated;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-checkbox',{staticClass:"mt-0 pt-0 checkbox",attrs:{"id":"pzo-checkbox","name":"isNotPzo","color":"success","error-messages":errors,"error":!_vm.isNotPzo && validated,"success":_vm.isNotPzo && !_vm.invalidFields.is_not_pzo && validated,"hide-details":""},model:{value:(_vm.isNotPzo),callback:function ($$v) {_vm.isNotPzo=$$v},expression:"isNotPzo"}}),_c('label',{staticClass:"ma-0 text--text cursor-pointer",attrs:{"for":"pzo-checkbox"}},[_vm._v(" Я не являюсь політично значущою особою ")])],1),_c('span',{staticClass:"width-150 error-msg error--text v-messages pl-8 position-absolute"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('v-row',{staticClass:"ma-0 mb-9 flex-nowrap"},[_c('validation-provider',{staticClass:"position-relative",attrs:{"name":"check","rules":("checkboxRequired|isInvalid:" + (_vm.invalidFields.gives_consent))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var validated = ref.validated;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('v-checkbox',{staticClass:"mt-0 pt-0 checkbox",attrs:{"id":"agreement-checkbox","error-messages":errors,"error":!_vm.givesConsent && validated,"success":_vm.givesConsent && !_vm.invalidFields.gives_consent && validated,"color":"success","hide-details":""},model:{value:(_vm.givesConsent),callback:function ($$v) {_vm.givesConsent=$$v},expression:"givesConsent"}}),_c('label',{staticClass:"ma-0 text--text",attrs:{"for":"agreement-checkbox"}},[_vm._v(" Я підтверджую інформацію та даю згоду з умовами. "),_c('span',{staticClass:"details-btn success--text darken-1 font-weight-medium\n                cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.togglePopUp(true)}}},[_vm._v(" Детальніше... ")])])],1),_c('span',{staticClass:"error-msg error--text v-messages pl-8 position-absolute"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),(_vm.popUpIsVisible)?_c('pop-up-window',[_c('about-agreements',{on:{"onPopUpClose":function($event){return _vm.togglePopUp(false)}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }