var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer"},[(_vm.isUnemployed)?_c('v-row',{staticClass:"ma-0 py-2 flex-nowrap"},[_c('v-checkbox',{staticClass:"mt-0 pt-0 checkbox",attrs:{"id":"previous-place-of-work","color":"success","hide-details":""},model:{value:(_vm.previousPlaceOfWork),callback:function ($$v) {_vm.previousPlaceOfWork=$$v},expression:"previousPlaceOfWork"}}),_c('label',{staticClass:"ma-0 text--text body-1",attrs:{"for":"previous-place-of-work"}},[_vm._v(" Попереднє місце роботи ")])],1):_vm._e(),(_vm.isEmployed || (_vm.isUnemployed && _vm.previousPlaceOfWork))?_c('v-row',{staticClass:"px-0 mt-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Назва компанії","rules":("required|companyName|isInvalid:" + (_vm.invalidFields.company_name))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('v-text-field',{ref:"input1",attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Назва компанії*","error-messages":errors,"error":!!_vm.invalidFields.company_name,"success":valid && _vm.companyName !== '' && !_vm.invalidFields.company_name},on:{"input":function($event){validate($event); _vm.removeFromInvalid('company_name')}},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}})]}}],null,false,1676751543)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"dateOfEmploymentPopUp",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Дата працевлаштування","rules":("required|isInvalid:" + (_vm.invalidFields.date_of_employment))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',_vm._g(_vm._b({ref:"input2",attrs:{"value":_vm.dateOfEmployment || _vm.formatDate('componentDateOfEmployment', _vm.dateFormat),"dense":"","filled":"","rounded":"","outlined":"","label":"Дата працевлаштування*","prepend-inner-icon":"mdi-calendar","color":"text","error-messages":errors,"error":!!_vm.invalidFields.date_of_employment,"success":valid && _vm.dateOfEmployment !== '' && _vm.dateOfEmployment !== null
                  && !_vm.invalidFields.date_of_employment,"readonly":""}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,325751204),model:{value:(_vm.dateOfEmploymentPopUp),callback:function ($$v) {_vm.dateOfEmploymentPopUp=$$v},expression:"dateOfEmploymentPopUp"}},[_c('v-date-picker',{ref:"dateOfEmploymentPicker",attrs:{"type":"month","color":"success","max":(new Date(Date.now()
              - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),"no-title":"","scrollable":"","reactive":"","locale":"uk"},on:{"input":_vm.saveDateOfEmployment}})],1)],1)],1):_vm._e(),(_vm.isEmployed)?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Посада","rules":("required|isInvalid:" + (_vm.invalidFields.position_id))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
              var validated = ref.validated;
return [_c('v-select',{ref:"input4",attrs:{"dense":"","filled":"","rounded":"","outlined":"","items":_vm.referenceBooks('job_position').length > 0 ?
              _vm.referenceBooks('job_position') : _vm.defaultDataForSelect,"color":"text","item-color":"text","item-value":"silver_id","item-text":"value","label":"Посада*","menu-props":{ offsetY: false },"error-messages":errors,"error":!!_vm.invalidFields.position_id || (validated && _vm.positionId === 0),"success":valid && !!_vm.positionId && !_vm.invalidFields.position_id},on:{"change":function($event){return _vm.removeFromInvalid('positionId')}},model:{value:(_vm.positionId),callback:function ($$v) {_vm.positionId=$$v},expression:"positionId"}})]}}],null,false,1615410632)})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Галузь","rules":("required|isInvalid:" + (_vm.invalidFields.segment_id))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
              var validated = ref.validated;
return [_c('v-select',{ref:"input5",attrs:{"dense":"","filled":"","rounded":"","outlined":"","color":"text","item-color":"text","items":_vm.referenceBooks('job_segment').length > 0 ?
              _vm.referenceBooks('job_segment') : _vm.defaultDataForSelect,"item-value":"silver_id","item-text":"value","label":"Галузь*","menu-props":{ offsetY: false },"error-messages":errors,"error":!!_vm.invalidFields.segment_id || (validated && _vm.segmentId === 0),"success":valid && !!_vm.segmentId && !_vm.invalidFields.segment_id},on:{"change":function($event){return _vm.removeFromInvalid('segmentId')}},model:{value:(_vm.segmentId),callback:function ($$v) {_vm.segmentId=$$v},expression:"segmentId"}})]}}],null,false,2069595933)})],1)],1):_vm._e(),(_vm.isUnemployed && _vm.previousPlaceOfWork)?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-menu',{ref:"dateOfReleasePopUp",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Дата звільнення","rules":("required|isInvalid:" + (_vm.invalidFields.date_of_release))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('v-text-field',_vm._g(_vm._b({ref:"input6",attrs:{"value":_vm.dateOfRelease || _vm.formatDate('componentDateOfRelease', _vm.dateFormat),"dense":"","filled":"","rounded":"","outlined":"","label":"Дата звільнення*","prepend-inner-icon":"mdi-calendar","color":"text","error-messages":errors,"error":!!_vm.invalidFields.date_of_release,"success":valid && _vm.dateOfRelease !== '' && _vm.dateOfRelease !== null
                  && !_vm.invalidFields.date_of_release,"readonly":""}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,4144145209),model:{value:(_vm.dateOfReleasePopUp),callback:function ($$v) {_vm.dateOfReleasePopUp=$$v},expression:"dateOfReleasePopUp"}},[_c('v-date-picker',{ref:"dateOfReleasePicker",attrs:{"type":"month","color":"success","no-title":"","reactive":"","scrollable":"","max":(new Date(Date.now()
              - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),"locale":"uk"},on:{"input":_vm.saveDateOfRelease}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.closePopUp('dateOfReleasePopUp')}}},[_vm._v(" Відміна ")])],1)],1)],1)],1):_vm._e(),(_vm.isStudent || _vm.isUnemployed)?_c('v-row',{staticClass:"mx-0",class:{ 'mt-3': _vm.isStudent }},[_c('v-col',{staticClass:"px-0"},[_c('validation-provider',{attrs:{"name":"Основне джерело доходу","rules":("required|isInvalid:" + (_vm.invalidFields.main_source_of_income))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
              var validated = ref.validated;
return [_c('v-select',{ref:"input8",attrs:{"value":_vm.mainSourceOfIncome,"dense":"","filled":"","rounded":"","outlined":"","color":"text","item-color":"text","item-value":"silver_id","return-object":"","item-text":"value","label":"Основне джерело доходу*","items":_vm.referenceBooks('dict_person_earns_type'),"menu-props":{ offsetY: false },"error-messages":errors,"error":!!_vm.invalidFields.main_source_of_income ||
             (validated && _vm.mainSourceOfIncome === 0),"success":valid && !!_vm.mainSourceOfIncome && !_vm.invalidFields.main_source_of_income},on:{"change":function($event){_vm.writeDownMainSourceOfIncome($event);
                     _vm.removeFromInvalid('mainSourceOfIncome')}}})]}}],null,false,489591745)})],1)],1):_vm._e(),((_vm.isStudent || _vm.isUnemployed) && _vm.parentsHelp)?_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"pa-0 subtitle-1 text--text text-h6 mb-3"},[_vm._v(" Дані батьків ")])],1),_c('v-col',{staticClass:"pa-0 d-flex flex-column flex-md-row",attrs:{"cols":"12"}},[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"ПІБ матері","rules":("required|alphaSpaceNums|isInvalid:" + (_vm.invalidFields.mother_full_name))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
                     var valid = ref.valid;
                     var validate = ref.validate;
return [_c('v-text-field',{ref:"input9",attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"ПІБ матері*","error-messages":errors,"error":!!_vm.invalidFields.mother_full_name,"success":valid && _vm.motherFullName !== '' && !_vm.invalidFields.mother_full_name},on:{"input":function($event){validate($event); _vm.removeFromInvalid('motherFullName')}},model:{value:(_vm.motherFullName),callback:function ($$v) {_vm.motherFullName=$$v},expression:"motherFullName"}})]}}],null,false,497204619)})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Номер телефону матері","rules":("required|uaPhone|isInvalid:" + (_vm.invalidFields.mother_phone))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
                     var valid = ref.valid;
                     var validate = ref.validate;
                     var validated = ref.validated;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('+380 (##) ###-##-##'),expression:"'+380 (##) ###-##-##'"}],ref:"input10",attrs:{"placeholder":"+380 (99) 999-99-99","dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Номер телефону матері*","error-messages":errors,"error":!!_vm.invalidFields.mother_phone,"success":valid && _vm.motherPhone.length === 19
                && !_vm.invalidFields.mother_phone && validated},on:{"input":function($event){return _vm.removeFromInvalid('mother_phone')}},model:{value:(_vm.motherPhone),callback:function ($$v) {_vm.motherPhone=$$v},expression:"motherPhone"}})]}}],null,false,2514118721)})],1)],1),_c('v-col',{staticClass:"d-flex pa-0 flex-column flex-md-row",attrs:{"cols":"12"}},[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"ПІБ батька","rules":("required|alphaSpaceNums|isInvalid:" + (_vm.invalidFields.father_full_name))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
                var validate = ref.validate;
return [_c('v-text-field',{ref:"input11",attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"ПІБ батька*","error-messages":errors,"error":!!_vm.invalidFields.father_full_name,"success":valid && _vm.fatherFullName !== '' && !_vm.invalidFields.father_full_name},on:{"input":function($event){validate($event); _vm.removeFromInvalid('father_full_name')}},model:{value:(_vm.fatherFullName),callback:function ($$v) {_vm.fatherFullName=$$v},expression:"fatherFullName"}})]}}],null,false,137912191)})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Номер телефону батька","rules":("required|uaPhone|isInvalid:" + (_vm.invalidFields.father_phone))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
                var validate = ref.validate;
                var validated = ref.validated;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('+380 (##) ###-##-##'),expression:"'+380 (##) ###-##-##'"}],ref:"input12",attrs:{"placeholder":"+380 (99) 999-99-99","dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Номер телефону батька*","error-messages":errors,"error":!!_vm.invalidFields.father_phone,"success":valid && _vm.fatherPhone.length === 19
                && !_vm.invalidFields.father_phone && validated},on:{"input":function($event){return _vm.removeFromInvalid('father_phone')}},model:{value:(_vm.fatherPhone),callback:function ($$v) {_vm.fatherPhone=$$v},expression:"fatherPhone"}})]}}],null,false,2873438158)})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }