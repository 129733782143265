<template>
  <div class="contact-person">
    <validation-observer
      ref="observer"
    >
      <v-row class="mb-2">
        <v-card-title class="text--text">
          Контактна особа
        </v-card-title>
      </v-row>

      <v-row
        no-gutters
        class="mt-0"
      >
        <v-col
          lg="4"
          md="6"
          cols="12"
          class="pr-md-2"
        >
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="Прізвище*"
            :rules="`required|min:${FMLMinLength}|alphaDash|max:${FMLMaxLength}
            |isInvalid:${invalidFields.last_name}`"
          >
            <v-text-field
              v-model="lastName"
              dense
              filled
              rounded
              outlined
              type="text"
              color="text"
              label="Прізвище*"
              :error-messages="errors"
              :error="!!invalidFields.last_name"
              :success="valid && lastName !== ''
                && !invalidFields.last_name"
              @input="validate($event); removeFromInvalid('lastName')"
              @keyup="capitalizeFirstLetter('lastName')"
            />
          </validation-provider>
        </v-col>
        <v-col
          lg="4"
          md="6"
          cols="12"
          class="pl-md-2 pr-lg-2"
        >
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="Ім'я*"
            :rules="`required|min:${FMLMinLength}|alphaDash|max:${FMLMaxLength}
            |isInvalid:${invalidFields.first_name}`"
          >
            <v-text-field
              v-model="firstName"
              dense
              filled
              rounded
              outlined
              type="text"
              color="text"
              label="Ім'я*"
              :error-messages="errors"
              :error="!!invalidFields.first_name"
              :success="valid && firstName !== ''
                && !invalidFields.first_name"
              @input="validate($event); removeFromInvalid('firstName')"
              @keyup="capitalizeFirstLetter('firstName')"
            />
          </validation-provider>
        </v-col>
        <v-col
          lg="4"
          cols="12"
          class="pl-lg-2"
        >
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="По батькові"
            :rules="`required|min:${FMLMinLength}|alphaDash|max:${FMLMaxLength}
            |isInvalid:${invalidFields.mid_name}`"
          >
            <v-text-field
              v-model="midName"
              dense
              filled
              rounded
              outlined
              type="text"
              color="text"
              label="По батькові*"
              :error-messages="errors"
              :error="!!invalidFields.mid_name"
              :success="valid && midName !== ''
                && !invalidFields.mid_name"
              @input="validate($event); removeFromInvalid('midName')"
              @keyup="capitalizeFirstLetter('midName')"
            />
          </validation-provider>
        </v-col>
        <v-col
          md="6"
          cols="12"
          class="pr-md-2"
        >
          <validation-provider
            v-slot="{ validate, errors, valid }"
            name="Телефон*"
            :rules="`required|uaPhone|isInvalid:${invalidFields.mobile_number}`"
          >
            <v-text-field
              ref="mobileNumber"
              v-model="mobileNumber"
              v-mask="'+380 (##) ###-##-##'"
              placeholder="+380 (99) 999-99-99"
              dense
              filled
              rounded
              outlined
              type="tel"
              color="text"
              label="Телефон*"
              :error-messages="errors"
              :error="!!invalidFields.mobile_number"
              :success="valid && mobileNumber.length === 19
                && !invalidFields.mobile_number"
              @input="validate($event); removeFromInvalid('mobileNumber');"
            />
          </validation-provider>
        </v-col>
        <v-col
          md="6"
          cols="12"
          class="pl-md-2"
        >
          <validation-provider
            v-slot="{ errors, valid, validated }"
            name="Ким доводиться клієнту*"
            :rules="`required|isInvalid:${invalidFields.contact_type_id}`"
          >
            <v-select
              v-model="contactTypeId"
              dense
              filled
              rounded
              outlined
              color="text"
              item-color="text"
              label="Ким доводиться клієнту*"
              :error-messages="errors"
              item-text="value"
              item-value="silver_id"
              :items="referenceBooks('contact_person_type').length > 0 ?
                referenceBooks('contact_person_type') : defaultDataForSelect"
              :menu-props="{ offsetY: false }"
              :error="!!invalidFields.contact_type_id || (validated && contactTypeId === 0)"
              :success="valid && !!contactTypeId && !invalidFields.contact_type_id"
              @change="removeFromInvalid('contactTypeId')"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row class="ma-0 mb-4 flex-nowrap">
        <validation-provider
          v-slot="{ errors, validated }"
          name="checkbox"
          :rules="`checkboxRequired|isInvalid:${invalidFields.is_not_pzo}`"
          class="position-relative"
        >
          <div class="d-flex flex-nowrap">
            <v-checkbox
              id="pzo-checkbox"
              v-model="isNotPzo"
              name="isNotPzo"
              class="mt-0 pt-0 checkbox"
              color="success"
              :error-messages="errors"
              :error="!isNotPzo && validated"
              :success="isNotPzo && !invalidFields.is_not_pzo && validated"
              hide-details
            />
            <label
              class="ma-0 text--text cursor-pointer"
              for="pzo-checkbox"
            >
              Я не являюсь політично значущою особою
              <!--              <span
                title="Політично значуща особа"
                class="white&#45;&#45;text"
              >ПЗО</span>-->
            </label>
          </div>
          <span class="width-150 error-msg error--text v-messages pl-8 position-absolute">
            {{ errors[0] }}
          </span>
        </validation-provider>
      </v-row>

      <v-row class="ma-0 mb-9 flex-nowrap">
        <validation-provider
          v-slot="{ errors, validated }"
          name="check"
          :rules="`checkboxRequired|isInvalid:${invalidFields.gives_consent}`"
          class="position-relative"
        >
          <div class="d-flex flex-nowrap">
            <v-checkbox
              id="agreement-checkbox"
              v-model="givesConsent"
              :error-messages="errors"
              :error="!givesConsent && validated"
              :success="givesConsent && !invalidFields.gives_consent && validated"
              class="mt-0 pt-0 checkbox"
              color="success"
              hide-details
            />
            <label
              class="ma-0 text--text"
              for="agreement-checkbox"
            >
              Я підтверджую інформацію та даю згоду з умовами.
              <span
                class="details-btn success--text darken-1 font-weight-medium
                  cursor-pointer"
                @click.prevent="togglePopUp(true)"
              >
                Детальніше...
              </span>
            </label>
          </div>
          <span class="error-msg error--text v-messages pl-8 position-absolute">
            {{ errors[0] }}
          </span>
        </validation-provider>
      </v-row>
    </validation-observer>
    <pop-up-window
      v-if="popUpIsVisible"
    >
      <about-agreements
        @onPopUpClose="togglePopUp(false)"
      />
    </pop-up-window>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import AboutAgreements from '@/components/cash-form-items/contact-person-form/AboutAgreements';
import PopUpWindow from '@/components/common/PopUpWindow';
import capitalizeFirstLetterMixin from '@/mixins/capitalizeFirstLetterMixin';

export default {
  name: 'ContactPerson',
  components: {
    PopUpWindow,
    AboutAgreements,
  },
  mixins: [capitalizeFirstLetterMixin],
  data() {
    return {
      phoneNumber: '',
      FMLMinLength: 2,
      FMLMaxLength: 40,
      popUpIsVisible: false,
      currentScope: 'contact_person',
    };
  },
  computed: {
    ...mapGetters([
      'referenceBooks',
    ]),
    ...mapState('cashForm', [
      'currentStep',
    ]),
    ...mapState([
      'defaultDataForSelect',
    ]),
    invalidFields() {
      return this.$store.state.cashForm.contact_person.invalid_fields;
    },
    mobileNumber: {
      get() {
        return this.$store.state.cashForm.contact_person.mobile_number;
      },
      set(value) {
        this.updateState('mobile_number', value);
      },
    },
    contactTypeId: {
      get() {
        return this.$store.state.cashForm.contact_person.contact_type_id;
      },
      set(value) {
        this.updateState('contact_type_id', value);
      },
    },
    lastName: {
      get() {
        return this.$store.state.cashForm.contact_person.last_name;
      },
      set(value) {
        this.updateState('last_name', value);
      },
    },
    firstName: {
      get() {
        return this.$store.state.cashForm.contact_person.first_name;
      },
      set(value) {
        this.updateState('first_name', value);
      },
    },
    midName: {
      get() {
        return this.$store.state.cashForm.contact_person.mid_name;
      },
      set(value) {
        this.updateState('mid_name', value);
      },
    },
    givesConsent: {
      get() {
        return this.$store.state.cashForm.contact_person.gives_consent;
      },
      set(value) {
        this.updateState('gives_consent', value);
      },
    },
    isNotPzo: {
      get() {
        return this.$store.state.cashForm.contact_person.is_not_pzo;
      },
      set(value) {
        this.updateState('is_not_pzo', value);
      },
    },
  },
  beforeCreate() {
    /*    this.$store.dispatch(
      'getReferenceBookStore',
      'CONTACT_PERSON_TYPE',
    ); */
  },
  mounted() {
    this.validateEditableForm();
  },
  methods: {
    ...mapActions('cashForm', [
      'removeFromInvalidFields',
    ]),
    handlePhoneNumberInput(e) {
      this.phoneNumber = e;
      this.mobileNumber = this.phoneNumber;
    },
    updateState(attribute, value) {
      this.$store.commit('cashForm/UPDATE_FORM_DATA', {
        parentObjName: this.currentScope,
        attribute,
        value,
      });
    },
    togglePopUp(bool) {
      this.popUpIsVisible = bool;
    },
    removeFromInvalid(propName) {
      const condition = !!this.invalidFields[propName];
      this.removeFromInvalidFields({
        parentObjName: this.currentScope,
        propName,
        condition,
      });
    },
    getObserver() {
      return this.$refs.observer;
    },
    validateEditableForm() {
      if (Object.keys(this.invalidFields).length > 0) {
        this.$refs.observer.validate();
      }
    },
    clearForm() {
      this.$store.commit('cashForm/CLEAR_FORM_DATA', {
        parentObjName: this.currentScope,
      });
      this.$refs.observer.reset();
    },
    removeFromInvalidObjects() {
      this.$store.commit('cashForm/REMOVE_FROM_INVALID_OBJECTS', {
        objName: this.currentScope,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .details-btn {
    &:hover {
      color: #d6d6d6 !important;
    }
  }

  .error-msg {
    bottom: -17px;
  }

  .width-150 {
    width: 150%;
  }
</style>
