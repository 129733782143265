<template>
  <div>
    <validation-observer ref="observer">
      <v-row
        v-if="isUnemployed"
        class="ma-0 py-2 flex-nowrap"
      >
        <v-checkbox
          id="previous-place-of-work"
          v-model="previousPlaceOfWork"
          class="mt-0 pt-0 checkbox"
          color="success"
          hide-details
        />
        <label
          class="ma-0 text--text body-1"
          for="previous-place-of-work"
        >
          Попереднє місце роботи
        </label>
      </v-row>

      <v-row
        v-if="isEmployed || (isUnemployed && previousPlaceOfWork)"
        class="px-0 mt-4"
      >
        <v-col
          cols="12"
          md="6"
          class="py-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="Назва компанії"
            :rules="`required|companyName|isInvalid:${invalidFields.company_name}`"
          >
            <v-text-field
              ref="input1"
              v-model="companyName"
              dense
              filled
              rounded
              outlined
              type="text"
              color="text"
              label="Назва компанії*"
              :error-messages="errors"
              :error="!!invalidFields.company_name"
              :success="valid && companyName !== '' && !invalidFields.company_name"
              @input="validate($event); removeFromInvalid('company_name')"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
        >
          <v-menu
            ref="dateOfEmploymentPopUp"
            v-model="dateOfEmploymentPopUp"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Дата працевлаштування"
                :rules="`required|isInvalid:${invalidFields.date_of_employment}`"
              >
                <v-text-field
                  ref="input2"
                  :value="dateOfEmployment || formatDate('componentDateOfEmployment', dateFormat)"
                  dense
                  filled
                  rounded
                  outlined
                  label="Дата працевлаштування*"
                  prepend-inner-icon="mdi-calendar"
                  color="text"
                  :error-messages="errors"
                  :error="!!invalidFields.date_of_employment"
                  :success="valid && dateOfEmployment !== '' && dateOfEmployment !== null
                    && !invalidFields.date_of_employment"
                  v-bind="attrs"
                  readonly
                  v-on="on"
                />
              </validation-provider>
            </template>
            <v-date-picker
              ref="dateOfEmploymentPicker"
              type="month"
              color="success"
              :max="(new Date(Date.now()
                - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
              no-title
              scrollable
              reactive
              locale="uk"
              @input="saveDateOfEmployment"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row v-if="isEmployed">
        <v-col
          md="6"
          cols="12"
          class="py-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validated }"
            name="Посада"
            :rules="`required|isInvalid:${invalidFields.position_id}`"
          >
            <v-select
              ref="input4"
              v-model="positionId"
              dense
              filled
              rounded
              outlined
              :items="referenceBooks('job_position').length > 0 ?
                referenceBooks('job_position') : defaultDataForSelect"
              color="text"
              item-color="text"
              item-value="silver_id"
              item-text="value"
              label="Посада*"
              :menu-props="{ offsetY: false }"
              :error-messages="errors"
              :error="!!invalidFields.position_id || (validated && positionId === 0)"
              :success="valid && !!positionId && !invalidFields.position_id"
              @change="removeFromInvalid('positionId')"
            />
          </validation-provider>
        </v-col>
        <v-col
          md="6"
          cols="12"
          class="py-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validated }"
            name="Галузь"
            :rules="`required|isInvalid:${invalidFields.segment_id}`"
          >
            <v-select
              ref="input5"
              v-model="segmentId"
              dense
              filled
              rounded
              outlined
              color="text"
              item-color="text"
              :items="referenceBooks('job_segment').length > 0 ?
                referenceBooks('job_segment') : defaultDataForSelect"
              item-value="silver_id"
              item-text="value"
              label="Галузь*"
              :menu-props="{ offsetY: false }"
              :error-messages="errors"
              :error="!!invalidFields.segment_id || (validated && segmentId === 0)"
              :success="valid && !!segmentId && !invalidFields.segment_id"
              @change="removeFromInvalid('segmentId')"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row v-if="isUnemployed && previousPlaceOfWork">
        <v-col
          cols="12"
          class="py-0"
        >
          <v-menu
            ref="dateOfReleasePopUp"
            v-model="dateOfReleasePopUp"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Дата звільнення"
                :rules="`required|isInvalid:${invalidFields.date_of_release}`"
              >
                <v-text-field
                  ref="input6"
                  :value="dateOfRelease || formatDate('componentDateOfRelease', dateFormat)"
                  dense
                  filled
                  rounded
                  outlined
                  label="Дата звільнення*"
                  prepend-inner-icon="mdi-calendar"
                  color="text"
                  :error-messages="errors"
                  :error="!!invalidFields.date_of_release"
                  :success="valid && dateOfRelease !== '' && dateOfRelease !== null
                    && !invalidFields.date_of_release"
                  v-bind="attrs"
                  readonly
                  v-on="on"
                />
              </validation-provider>
            </template>
            <v-date-picker
              ref="dateOfReleasePicker"
              type="month"
              color="success"
              no-title
              reactive
              scrollable
              :max="(new Date(Date.now()
                - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
              locale="uk"
              @input="saveDateOfRelease"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="closePopUp('dateOfReleasePopUp')"
              >
                Відміна
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row
        v-if="isStudent || isUnemployed"
        class="mx-0"
        :class="{ 'mt-3': isStudent }"
      >
        <v-col
          class="px-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validated }"
            name="Основне джерело доходу"
            :rules="`required|isInvalid:${invalidFields.main_source_of_income}`"
          >
            <v-select
              ref="input8"
              :value="mainSourceOfIncome"
              dense
              filled
              rounded
              outlined
              color="text"
              item-color="text"
              item-value="silver_id"
              return-object
              item-text="value"
              label="Основне джерело доходу*"
              :items="referenceBooks('dict_person_earns_type')"
              :menu-props="{ offsetY: false }"
              :error-messages="errors"
              :error="!!invalidFields.main_source_of_income ||
               (validated && mainSourceOfIncome === 0)"
              :success="valid && !!mainSourceOfIncome && !invalidFields.main_source_of_income"
              @change="writeDownMainSourceOfIncome($event);
                       removeFromInvalid('mainSourceOfIncome')"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row
        v-if="(isStudent || isUnemployed) && parentsHelp"
        class="mt-0"
      >
        <v-col cols="12">
          <v-subheader class="pa-0 subtitle-1 text--text text-h6 mb-3">
            Дані батьків
          </v-subheader>
        </v-col>
        <v-col
          cols="12"
          class="pa-0 d-flex flex-column flex-md-row"
        >
          <v-col
            md="6"
            cols="12"
            class="py-0"
          >
            <validation-provider
              v-slot="{ errors, valid, validate }"
              name="ПІБ матері"
              :rules="`required|alphaSpaceNums|isInvalid:${invalidFields.mother_full_name}`"
            >
              <v-text-field
                ref="input9"
                v-model="motherFullName"
                dense
                filled
                rounded
                outlined
                type="text"
                color="text"
                label="ПІБ матері*"
                :error-messages="errors"
                :error="!!invalidFields.mother_full_name"
                :success="valid && motherFullName !== '' && !invalidFields.mother_full_name"
                @input="validate($event); removeFromInvalid('motherFullName')"
              />
            </validation-provider>
          </v-col>
          <v-col
            md="6"
            cols="12"
            class="py-0"
          >
            <validation-provider
              v-slot="{ errors, valid, validate, validated }"
              name="Номер телефону матері"
              :rules="`required|uaPhone|isInvalid:${invalidFields.mother_phone}`"
            >
              <v-text-field
                ref="input10"
                v-model="motherPhone"
                v-mask="'+380 (##) ###-##-##'"
                placeholder="+380 (99) 999-99-99"
                dense
                filled
                rounded
                outlined
                type="text"
                color="text"
                label="Номер телефону матері*"
                :error-messages="errors"
                :error="!!invalidFields.mother_phone"
                :success="valid && motherPhone.length === 19
                  && !invalidFields.mother_phone && validated"
                @input="removeFromInvalid('mother_phone')"
              />
            </validation-provider>
          </v-col>
        </v-col>
        <v-col
          class="d-flex pa-0 flex-column flex-md-row"
          cols="12"
        >
          <v-col
            md="6"
            cols="12"
            class="py-0"
          >
            <validation-provider
              v-slot="{ errors, valid, validate }"
              name="ПІБ батька"
              :rules="`required|alphaSpaceNums|isInvalid:${invalidFields.father_full_name}`"
            >
              <v-text-field
                ref="input11"
                v-model="fatherFullName"
                dense
                filled
                rounded
                outlined
                type="text"
                color="text"
                label="ПІБ батька*"
                :error-messages="errors"
                :error="!!invalidFields.father_full_name"
                :success="valid && fatherFullName !== '' && !invalidFields.father_full_name"
                @input="validate($event); removeFromInvalid('father_full_name')"
              />
            </validation-provider>
          </v-col>
          <v-col
            md="6"
            cols="12"
            class="py-0"
          >
            <validation-provider
              v-slot="{ errors, valid, validate, validated }"
              name="Номер телефону батька"
              :rules="`required|uaPhone|isInvalid:${invalidFields.father_phone}`"
            >
              <v-text-field
                ref="input12"
                v-model="fatherPhone"
                v-mask="'+380 (##) ###-##-##'"
                placeholder="+380 (99) 999-99-99"
                dense
                filled
                rounded
                outlined
                type="text"
                color="text"
                label="Номер телефону батька*"
                :error-messages="errors"
                :error="!!invalidFields.father_phone"
                :success="valid && fatherPhone.length === 19
                  && !invalidFields.father_phone && validated"
                @input="removeFromInvalid('father_phone')"
              />
            </validation-provider>
          </v-col>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import formatDateMixin from '@/mixins/formatDateMixin';

export default {
  name: 'AboutWorkplace',
  mixins: [formatDateMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    currentScope: String,
    // eslint-disable-next-line vue/require-default-prop
    currentBusynessCode: String,
  },
  data() {
    return {
      mainSourceOfIncomeCode: '',
      dateFormat: 'YYYY',
      dateOfReleasePopUp: false,
      dateOfEmploymentPopUp: false,
      test: false,
      student: true,
    };
  },
  watch: {
    dateOfEmploymentPopUp(val) {
      // eslint-disable-next-line no-return-assign,no-unused-expressions
      val && this.$nextTick(() => (this.$refs.dateOfEmploymentPicker.activePicker = 'YEAR'));
    },
    dateOfReleasePopUp(val) {
      // eslint-disable-next-line no-return-assign,no-unused-expressions
      val && this.$nextTick(() => (this.$refs.dateOfReleasePicker.activePicker = 'YEAR'));
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState([
      'defaultDataForSelect',
    ]),
    ...mapGetters([
      'referenceBooks',
    ]),
    invalidFields() {
      return this.$store.state.cashForm.additional_info.invalid_fields;
    },
    isEmployed() {
      return this.currentBusynessCode === 'underemployment' || this.currentBusynessCode === 'full-employment'
        || this.currentBusynessCode === 'fop';
    },
    isUnemployed() {
      return this.currentBusynessCode === 'unemployed' || this.currentBusynessCode === 'pensioner'
        || this.currentBusynessCode === 'student';
    },
    isStudent() {
      return this.currentBusynessCode === 'student';
    },
    parentsHelp() {
      return this.mainSourceOfIncomeCode === 'parents';
    },
    previousPlaceOfWork: {
      get() {
        return this.$store.state.cashForm.additional_info.previous_place_of_work;
      },
      set(value) {
        this.updateState('previous_place_of_work', value);
      },
    },
    companyName: {
      get() {
        return this.$store.state.cashForm.additional_info.company_name;
      },
      set(value) {
        this.updateState('company_name', value);
      },
    },
    dateOfEmployment: {
      get() {
        return this.$store.state.cashForm.additional_info.date_of_employment;
      },
      set(value) {
        this.updateState('date_of_employment', value);
      },
    },
    positionId: {
      get() {
        return this.$store.state.cashForm.additional_info.position_id;
      },
      set(value) {
        this.updateState('position_id', value);
      },
    },
    segmentId: {
      get() {
        return this.$store.state.cashForm.additional_info.segment_id;
      },
      set(value) {
        this.updateState('segment_id', value);
      },
    },
    dateOfRelease: {
      get() {
        return this.$store.state.cashForm.additional_info.date_of_release;
      },
      set(value) {
        this.updateState('date_of_release', value);
      },
    },
    mainSourceOfIncome: {
      get() {
        return this.$store.state.cashForm.additional_info.main_source_of_income;
      },
      set(value) {
        this.updateState('main_source_of_income', value);
      },
    },
    motherFullName: {
      get() {
        return this.$store.state.cashForm.additional_info.mother_full_name;
      },
      set(value) {
        this.updateState('mother_full_name', value);
      },
    },
    motherPhone: {
      get() {
        return this.$store.state.cashForm.additional_info.mother_phone;
      },
      set(value) {
        this.updateState('mother_phone', value);
      },
    },
    fatherFullName: {
      get() {
        return this.$store.state.cashForm.additional_info.father_full_name;
      },
      set(value) {
        this.updateState('father_full_name', value);
      },
    },
    fatherPhone: {
      get() {
        return this.$store.state.cashForm.additional_info.father_phone;
      },
      set(value) {
        this.updateState('father_phone', value);
      },
    },
  },
  beforeCreate() {
    this.$store.dispatch(
      'getReferenceBookStore',
      'JOB_POSITION, JOB_SEGMENT, DICT_PERSON_EARNS_TYPE',
    ).then(() => {
      this.selectMainSourceOfIncome();
    })
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions('cashForm', [
      'removeFromInvalidFields',
    ]),
    saveDateOfEmployment(date) {
      this.$refs.dateOfEmploymentPopUp.save(date);
      this.$refs.dateOfEmploymentPicker.activePicker = 'YEAR';
      this.dateOfEmployment = `${this.$refs.dateOfEmploymentPicker.inputYear}`;
      this.dateOfEmploymentPopUp = false;
      this.removeFromInvalid('dateOfEmployment');
    },
    saveDateOfRelease(date) {
      this.$refs.dateOfReleasePopUp.save(date);
      this.$refs.dateOfReleasePicker.activePicker = 'YEAR';
      this.dateOfRelease = `${this.$refs.dateOfReleasePicker.inputYear}`;
      this.dateOfReleasePopUp = false;
      this.removeFromInvalid('dateOfRelease');
    },
    updateState(attribute, value) {
      this.$store.commit('cashForm/UPDATE_FORM_DATA', {
        parentObjName: this.currentScope,
        attribute,
        value,
      });
    },
    closePopUp(popUpName) {
      this[popUpName] = false;
    },
    removeFromInvalid(propName) {
      const condition = !!this.invalidFields[propName];
      this.removeFromInvalidFields({
        parentObjName: this.currentScope,
        propName,
        condition,
      });
    },
    writeDownMainSourceOfIncome(obj) {
      if (obj) {
        this.mainSourceOfIncome = obj.silver_id;
        this.mainSourceOfIncomeCode = obj.code;
      }
    },
    selectMainSourceOfIncome() {
      if (this.mainSourceOfIncome) {
        this.referenceBooks('dict_person_earns_type').forEach((item) => {
          if (item.silverId === this.mainSourceOfIncome) {
            this.writeDownMainSourceOfIncome(item);
          }
        });
      }
    },
    resetInputs() {
      this.$refs.observer.reset();
      this.previousPlaceOfWork = false;
      this.mainSourceOfIncome = 0;

      const items = ['dateOfEmployment', 'dateOfRelease', 'companyName'];
      items.forEach((item) => this.updateState(item, ''));

      this.positionId = 0;
      this.segmentId = 0;
      this.componentDateOfEmployment = '';
      this.componentDateOfRelease = '';
      this.$refs.observer.reset();
    },
  },
};
</script>

<style scoped>

</style>
