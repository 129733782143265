<template>
  <div class="address">
    <form>
      <validation-observer
        v-for="(item) of addressesTypes"
        :key="item"
        ref="observer"
      >
        <address-type
          :address-type-from-parent="item"
          :current-scope="currentScope"
          @onFormCreated="validateEditableForm"
        />
      </validation-observer>
    </form>
  </div>
</template>

<script>
import AddressType from '@/components/cash-form-items/address-form/AddressType';
import { mapState } from 'vuex';

export default {
  name: 'Address',
  components: {
    AddressType,
  },
  data() {
    return {
      addressesTypes: ['registration', 'accommodation'],
      currentScope: 'address',
    };
  },
  computed: {
    ...mapState('cashForm', [
      'currentStep',
      'formIsEditing',
    ]),
    addressesMatch: {
      get() {
        return this.$store.state.cashForm.address.addresses_match;
      },

      set(value) {
        this.$store.commit('cashForm/UPDATE_FORM_DATA', {
          parentObjName: this.currentScope,
          attribute: 'addresses_match',
          value,
        });
      },
    },
  },
  beforeCreate() {
    this.$store.dispatch(
      'getReferenceBookStore',
      'DICT_STREET_TYPE, LOCALITY_TYPE, REGION',
    );
  },
  methods: {
    getObserver() {
      const o1 = this.$refs.observer[0];
      const o2 = this.$refs.observer[1];
      return [o1, o2];
    },
    validateEditableForm(obj) {
      if (Object.keys(obj).length > 0) {
        this.$refs.observer.forEach((item) => item.validate());
      }
    },
    clearForm() {
      this.addressesTypes.forEach((item) => {
        this.$store.commit('cashForm/CLEAR_FORM_DATA', {
          parentObjName: this.currentScope,
          childObjName: item,
        });
      });

      const numberOfForms = 2;
      for (let i = 0; i < numberOfForms; i += 1) {
        this.$refs.observer[i].reset();
      }
    },
    removeFromInvalidObjects() {
      this.$store.commit('cashForm/REMOVE_FROM_INVALID_OBJECTS', {
        objName: this.currentScope,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
