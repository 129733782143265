<template>
  <div class="wrapper background mx-auto">
    <v-card-title
      class="card-title success py-2 py-sm-3 px-3 text-center body-1 font-weight-medium
      text-sm-h6 text-sm-left white--text"
    >
      ЗГОДА НА ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ ТА ДОСТУП ДО КРЕДИТНОЇ ІСТОРІЇ
    </v-card-title>

    <v-card-text class="content pt-2 pt-sm-3 text-justify custom-scroll">
      <p class="mb-1">
        1. Я (далі – Споживач), здійснюючи подання заявки на кредит та персональної інформації
        щодо себе в Інформаційно-телекомунікаційній системі ТОВ «СУЧАСНИЙ ФАКТОРИНГ»,
        код юридичної особи 35310044 (далі -Товариство), доступ до якої забезпечується через веб-
        сайт з доменним ім’ям www.sfactor.com.ua (далі- Веб-сайт) або через веб-форму доступу до
        Веб-сайту, що доступна Споживачу в торгових точках (магазинах, інтернет магазинах), з
        якими у Товариства наявні договірні відносини щодо співробітництва (далі - веб-форма
        доступу до Веб-сайту) або через Контакт-центр Товариства за допомогою телефонного
        зв’язку (номери телефонів зазначені на Веб-сайті) та проставляючи відповідну відмітку на
        Веб-сайті та/або вводячи (озвучуючи Контакт- центру) код з СМС (електронний підпис), що
        передбачає надання цієї згоди, надаю свою однозначну, беззастережну і необмежену згоду
        на:
      </p>

      <p>
        1.1. <b>Обробку персональних даних</b>, у тому числі, на їх збір, систематизацію,
        накопичення,
        зберігання, уточнення (оновлення та зміну), використання, розповсюдження, передачу,
        знеособлення, блокування та знищення будь-якої інформації, що стосується мене, зокрема,
        але не виключно, інформації щодо: статі, прізвища, імені, по-батькові, паспортних даних,
        реєстраційного номеру облікової картки платника податків, дати, місця та року народження,
        фотографії документу, що посвідчує особу або особистої фотографії та/або відео-запису
        особи (в тому числі, але не виключно з документом, що посвідчує особу), громадянства,
        адреси фактичного проживання та реєстрації, сімейного, соціального, майнового становища,
        освіти, професії, займаної посади , найменування та реквізитів роботодавця або навчального
        закладу, доходів, номерів контактних телефонів, адреси електронної пошти, голосових
        записів, тощо (далі – «Персональні дані»).
      </p>

      <p class="mb-1">
        1.1.1. Метою обробки Товариством Персональних даних Споживача є:
      </p>
      <ul>
        <li>
          аналіз наданих Споживачем даних для прийняття Товариством рішення щодо
          укладення кредитного договору;
        </li>
        <li>
          здійснення Товариством своєї фінансово-господарської діяльності, виконання умов
          укладених договорів;
        </li>
        <li>
          пропонування повного переліку послуг Товариства, в тому числі, але не виключно,
          шляхом направлення комерційних повідомлень будь-яким доступним каналом зв’язку
          з Споживачем;
        </li>
        <li>
          інформування Споживача про діючі умови програми лояльності, бонуси, промокоди,
          акції, спеціальні пропозиції;
        </li>
        <li>
          надання фінансової інформації;
        </li>
        <li>
          інформування Споживача (у т.ч. шляхом залучення третіх осіб) про наявність
          заборгованості, необхідність та порядок її погашення відповідно до умов укладеного
          кредитного договору;
        </li>
        <li>
          передача персональних даних Споживача в бюро кредитних історій для формування
          кредитної історії Споживача;
        </li>
        <li>
          інша мета, що не суперечить Закону України «Про захист персональних даних».
        </li>
      </ul>

      <p class="mb-1 mt-1">
        1.1.2. На виконання поставленої мети, надаю свою згоду на:
      </p>
      <ul>
        <li>
          Передачу, поширення (у тому числі транскордонне) та надання своїх даних органам
          державної влади та їх структурним підрозділам, банкам та небанківським фінансовим
          установам, факторинговим компаніям (у зв’язку із відступленням права вимоги або
          укладенням договору купівлі-продажу прав вимоги за договорами позики, інших
          договорів), усім бюро кредитних історій, які створені та здійснюють свою діяльність
          відповідно до законодавства України (далі – Бюро кредитних історій) та іншим
          установам, організаціям, юридичним особам;
        </li>
        <li>
          Здійснення запитів з використанням Персональних даних до Бюро кредитних історій,
          сервісів дистанційної верифікації та інших сервісів, що у порядку, передбаченому
          законодавством України, надають інформацію про мене та отримання додаткових
          даних щодо мене з наступною обробкою таких даних;
        </li>
        <li>
          Обробку моїх телекомунікаційних даних, отриманих під час користування Веб-сайтом
          Товариства, що включають: файли cookie, IP-адреси, параметри та налаштування
          інтернет-браузерів технічних пристроїв та іншу технічну інформацію;
        </li>
        <li>
          Обробку та передачу Товариству операторами мобільного зв&#39;язку телекомунікаційних
          даних, зібраних стосовно номерів телефонів вказаних при заповнені форм на Веб-
          сайті (заповнені веб форм доступу до Веб сайту);
        </li>
        <li>
          Обробку та передачу Кредитору операторами мобільного зв&#39;язку телекомунікаційних
          даних, інформації про надання та отримання Споживачем телекомунікаційних послуг,
          у тому числі щодо MSISDN для визначення коефіцієнта в рамках користування
          послугами мережі рухомого (мобільного) зв’язку, зібраних стосовно номерів
          телефонів, вказаних Споживачем в ІТС Товариства, при заповненні форм на Веб-
          сайті, в кредитному правочині, укладеному з Товариством або іншим чином;
        </li>
        <li>
          Фіксацію телефонних розмов, їх обробку та подальше зберігання.
        </li>
      </ul>

      <p class="mt-2 mb-2">
        1.1.3. Здійснення дій зазначених у пунктах 1.1.1.- 1.1.2. Згоди не потребує додаткового
        інформування мене (Споживача).
      </p>

      <p class="mt-2 mb-2">
        1.2. <b>доступ Товариства до моєї кредитної історії</b>, збір, зберігання, використання та
        розповсюдження через Бюро кредитних історій інформації про мене (в тому числі
        інформації, що міститься у державних реєстрах та інших базах даних публічного
        використання) у порядку, визначеному Законом України «Про організацію формування та
        обігу кредитних історій»;
      </p>

      <p class="mt-2 mb-2">
        2. Вказані згоди надаються на весь строк зберігання Товариством відповідних категорій
        документів/інформації, визначений згідно законодавства, в тому числі після припинення/не
        встановлення правовідносин між мною та Товариством. Я також погоджуюсь, що дані згоди
        дають право Товариству неоднократно обробляти персональні дані та запитувати необхідну
        інформацію від юридичних осіб, в тому числі отримувати необмежену кількість разів
        кредитні звіти від Бюро кредитних історій.
      </p>

      <p class="mt-2 mb-2">
        3. Споживач підтверджує, що він має законне право на надання усіх без виключення даних,
        зазначених ним при реєстрації, заповненні форм, наданні документів та іншої інформації на
        Веб-сайті (через веб-форму доступу до Веб-сайту) Товариства та переданих Товариству.
      </p>

      <p class="mt-2 mb-2">
        4. Споживач погоджується на використання Товариством з метою комунікації з Споживачем
        контактної інформації, що була зазначена Споживачем на Веб-сайті та/або через веб- форму
        доступу до Веб-сайту та/або окремо повідомлена Споживачем Товариству у будь-який спосіб
        та/або зазначена в укладеному між Споживачем та Товариством договорі та/або законно
        отримана Товариством з інших джерел. Така комунікація може здійснюватися поштовим
        зв’язком, електронною поштою, телефонним зв’язком, спеціальними засобами програмного
        забезпечення, які пов&#39;язані з наданими Споживачем телефонними номерами (Viber,
        WhatsApp та ін.), засобами зв&#39;язку у соціальних мережах (Facebook та ін.) та іншими
        електронними засобами зв’язку.
      </p>

      <div class="mt-2 mb-2">
        5. Споживач, здійснюючи реєстрацію, заповнюючи форми, надаючи документи та іншу
        інформацію щодо себе на Веб-сайті або через веб-форму доступу до Веб-сайту, підтверджує,
        що він:
        <ul class="list-style-none">
          <li>
            - повідомлений про володільця персональних даних;
          </li>
          <li>
            - про склад та зміст зібраних Товариством Персональних даних Споживача;
          </li>
          <li>
            - про включення Персональних даних до бази персональних даних Товариства;
          </li>
          <li>
            - про свої права, передбачені Законом України «Про захист персональних даних» та Законом
            України «Про організацію формування та обігу кредитних історій»;
          </li>
          <li>
            - про мету збору Персональних даних та третіх осіб, яким передаються Персональні дані;
          </li>
          <li>
            - засвідчує, що склад та зміст Персональних даних в повній мірі є відповідними,
            адекватними та ненадмірними стосовно визначеної цією Згодою мети їх обробки.
          </li>
        </ul>
      </div>

      <p class="mt-2 mb-2">
        6. Товариство забезпечує належний захист Персональних даних Споживача у спосіб,
        визначений чинним законодавством України. Захист Персональних даних передбачає заходи,
        спрямовані на запобігання випадковій втраті, знищенню, незаконній обробці, у тому числі
        незаконному доступу чи знищенню Персональних даних.
      </p>

      <p class="mt-2 mb-2">
        7. Товариство веде облік операцій, пов’язаних з обробкою Персональних даних Споживача
        та доступом до них відповідно до вимог законодавства.
      </p>

      <p class="mt-2 mb-2">
        Мої прізвище, ім’я, по батькові, реєстраційний номер облікової картки платника податків,
        місце та день підтвердження мною цієї згоди визначаються відповідно до електронних
        даних, що дають можливість здійснити ідентифікацію мене як фізичної особи та містяться в
        Інформаційно-телекомунікаційній системі Товариства
      </p>
    </v-card-text>

    <v-row class="ma-0">
      <p class="divider col-12 cardLightGray pa-0 ma-0" />
      <v-btn
        rounded
        color="success"
        class="my-3 mx-auto"
        @click="$emit('onPopUpClose')"
      >
        Продовжити
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AboutAgreements',
};
</script>

<style lang="scss" scoped>
    .wrapper {
      width: 90vw;

      @media screen and (min-width: 600px) {
        width: 500px;
      }

      @media screen and (min-width: 900px) {
        width: 800px;
      }
    }

    .content {
      max-height: 55vh;
      overflow-y: auto;
    }

    .card-title {
      border-radius: 10px 10px 0 0;
    }

    .divider {
      height: 1px;
    }

    .list-style-none {
      list-style: none;
    }
</style>
