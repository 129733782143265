import moment from 'moment';

export default {
  methods: {
    formatDate(propFromComponent, format = 'DD.MM.YYYY') {
      if (this[propFromComponent]) {
        return moment(this[propFromComponent]).format(format);
      }

      return '';
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split('.');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    dateParser(date) {
      const format = 'DD.MM.YYYY';
      return moment(date).format(format);
    },
  },
};
