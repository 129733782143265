<template>
  <div class="document-data">
    <validation-observer ref="observer">
      <v-row class="mb-1">
        <v-card-title class="text--text">
          Паспортні дані
        </v-card-title>
      </v-row>

      <v-row class="ma-0">
        <v-checkbox
          id="id-card-checkbox"
          v-model="typeOfDocToggle"
          class="mt-0 pt-0 checkbox"
          color="success"
          hide-details
          @change="writeDownCurrentDocument($event); clearForm()"
        />
        <label
          class="ma-0 text--text body-1 cursor-pointer"
          for="id-card-checkbox"
        >
          Паспорт нового зразка
        </label>
      </v-row>

      <about
        ref="about"
        :key="currentDocumentType"
        :document-type="currentDocumentType"
        :current-scope="currentScope"
        class="mt-8"
      />
    </validation-observer>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import About from '@/components/cash-form-items/document-data-form/About';

export default {
  name: 'Index',
  components: {
    About,
  },
  data() {
    return {
      currentDocumentPhotosArr: [],
      currentDocumentType: '',
      currentScope: 'document_data',
      availableDocumentTypes: {
        passport: 'passport',
        idCard: 'idCard',
      },
    };
  },
  computed: {
    ...mapState('cashForm', [
      'currentStep',
      'formIsEditing',
    ]),
    ...mapGetters('cashForm', [
      'isIdCard',
      'docTypeId',
    ]),
    invalidFields() {
      return this.$store.state.cashForm.document_data.invalid_fields;
    },
    docTypeId: {
      get() {
        return this.$store.state.cashForm.document_data.doc_type_id;
      },
      set(value) {
        this.$store.commit('cashForm/UPDATE_FORM_DATA', {
          parentObjName: this.currentScope,
          attribute: 'doc_type_id',
          value,
        });
      },
    },
    hasUniqueNumberInRegistrationCertificate: {
      get() {
        return this.$store.state.cashForm.document_data
          .has_unique_number_in_registration_certificate;
      },
      set(value) {
        this.$store.commit('cashForm/UPDATE_FORM_DATA', {
          parentObjName: this.currentScope,
          attribute: 'has_unique_number_in_registration_certificate',
          value,
        });
      },
    },
    typeOfDocToggle: {
      get() {
        return this.$store.state.cashForm.document_data.is_id_card;
      },
      set(value) {
        this.$store.commit('cashForm/UPDATE_FORM_DATA', {
          parentObjName: this.currentScope,
          attribute: 'is_id_card',
          value,
        });
      },
    },
  },
  mounted() {
    this.writeDownCurrentDocument();
    this.validateEditableForm();
  },
  methods: {
    selectDocumentType() {
      const timeout = setTimeout(() => {
        this.writeDownCurrentDocument(this.isIdCard);
        clearTimeout(timeout);
      }, 500);
    },
    getObserver() {
      return this.$refs.observer;
    },
    validateEditableForm() {
      if (Object.keys(this.invalidFields).length > 0) {
        this.$refs.observer.validate();
        this.selectDocumentType();
      }
    },
    selectDocTypeId() {
      this.docTypeId = this.typeOfDocToggle ? 2 : 1;
    },
    clearForm() {
      this.$store.commit('cashForm/CLEAR_FORM_DATA', {
        parentObjName: this.currentScope,
      });
      this.hasUniqueNumberInRegistrationCertificate = null;
      this.$refs.about.clearDate();
      this.$refs.observer.reset();
      this.selectDocTypeId();
    },
    selectDocument(documentName) {
      let document = '';
      switch (documentName) {
        case 'Паспорт України': document = 'passport'; break;
        case 'Паспорт громадянина України у вигляді ID карти': document = 'idCard'; break;
        default: document = ''; break;
      }
      this.currentDocumentType = document;
      return document;
    },
    writeDownCurrentDocument() {
      this.currentDocumentType = this.typeOfDocToggle ? this.availableDocumentTypes.idCard
        : this.availableDocumentTypes.passport;
    },
    removeFromInvalidObjects() {
      this.$store.commit('cashForm/REMOVE_FROM_INVALID_OBJECTS', {
        objName: this.currentScope,
      });
    },
  },
};
</script>

<style scoped>
</style>
