<template>
  <div
    class="registration"
  >
    <v-card-title class="text--text mx-n3">
      <template v-if="isAccommodation">
        Адреса проживання
      </template>

      <template v-else>
        Адреса реєстрації
      </template>
    </v-card-title>

    <v-row class="mt-0">
      <v-col
        cols="12"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors, valid, validated }"
          name="Область"
          :rules="`required|isInvalid:${invalidFields.region_id}`"
        >
          <v-select
            v-model="regionId"
            dense
            filled
            rounded
            outlined
            label="Область*"
            color="text"
            item-color="text"
            :items="referenceBooks('region').length > 0 ?
              referenceBooks('region') : defaultDataForSelect"
            item-text="value"
            item-value="silver_id"
            :error-messages="errors"
            :menu-props="{ offsetY: false }"
            :error="!!invalidFields.region_id || (validated && regionId === 0)"
            :success="valid && !!regionId && !invalidFields.region_id"
            @change="matchInputModels($event); removeFromInvalid('regionId')"
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        lg="4"
        md="6"
        cols="12"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors, valid, validate }"
          name="Район"
          :rules="`alphaDash|isInvalid:${invalidFields.district}|max:
          ${maxNumOfCharactersForStreet}`"
        >
          <v-text-field
            v-model="district"
            dense
            filled
            rounded
            outlined
            label="Район"
            color="text"
            item-color="text"
            :error-messages="errors"
            :menu-props="{ offsetY: false }"
            :error="!!invalidFields.district"
            :success="valid && !!district && !invalidFields.district"
            @input="matchInputModels(); validate($event); removeFromInvalid('district')"
            @keyup="capitalizeFirstLetter('district')"
          />
        </validation-provider>
      </v-col>

      <v-col
        lg="4"
        md="6"
        cols="12"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors, valid, validate, validated }"
          name="Тип населеного пункту"
          :rules="`required|isInvalid:${invalidFields.locality_type_id}`"
        >
          <v-select
            v-model="localityTypeId"
            dense
            filled
            rounded
            outlined
            label="Тип населеного пункту*"
            color="text"
            item-color="text"
            item-text="value"
            item-value="silver_id"
            :items="referenceBooks('locality_type').length > 0 ?
              referenceBooks('locality_type') : defaultDataForSelect"
            :error-messages="errors"
            :menu-props="{ offsetY: false }"
            :error="!!invalidFields.locality_type_id || (validated && localityTypeId === 0)"
            :success="valid && !!localityTypeId && !invalidFields.locality_type_id
              || referenceBooks('locality_type').length <= 0"
            @change="matchInputModels(); validate($event); removeFromInvalid('localityTypeId')"
          />
        </validation-provider>
      </v-col>

      <v-col
        lg="4"
        md="12"
        cols="12"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors, valid, validate }"
          name="Населений пункт"
          :rules="`required|alphaDash:true|isInvalid:${invalidFields.locality}|max:
          ${maxNumOfCharactersForStreet}`"
        >
          <v-text-field
            v-model="locality"
            dense
            filled
            rounded
            outlined
            label="Населений пункт*"
            color="text"
            item-color="text"
            :error-messages="errors"
            :menu-props="{ offsetY: false }"
            :error="!!invalidFields.locality"
            :success="valid && !!locality && !invalidFields.locality"
            @input="matchInputModels(); validate($event); removeFromInvalid('locality')"
            @keyup="capitalizeFirstLetter('locality')"
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        md="6"
        cols="12"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors, valid, validated }"
          name="Тип вулиці"
          :rules="`required|isInvalid:${invalidFields.street_type_id}`"
        >
          <v-select
            v-model="streetTypeId"
            dense
            filled
            rounded
            outlined
            label="Тип вулиці*"
            color="text"
            item-color="text"
            item-text="value"
            item-value="silver_id"
            :items="referenceBooks('dict_street_type').length > 0 ?
              referenceBooks('dict_street_type') : defaultDataForSelect"
            :error-messages="errors"
            :menu-props="{ offsetY: false }"
            :error="!!invalidFields.street_type_id || (validated && streetTypeId === 0)"
            :success="valid && !!streetTypeId && !invalidFields.street_type_id
              || referenceBooks('dict_street_type').length <= 0"
            @change="matchInputModels($event); removeFromInvalid('streetTypeId')"
          />
        </validation-provider>
      </v-col>

      <v-col
        md="6"
        cols="12"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors, valid, validate }"
          name="Назва вулиці"
          :rules="`required|alphaSpaceNums|isInvalid:${invalidFields.street}|max:${
            maxNumOfCharactersForStreet}`"
        >
          <v-text-field
            v-model="street"
            dense
            filled
            rounded
            outlined
            label="Назва вулиці*"
            color="text"
            item-color="text"
            :error-messages="errors"
            :menu-props="{ offsetY: false }"
            :error="!!invalidFields.street"
            :success="valid && !!street && !invalidFields.street"
            @input="matchInputModels(); validate($event); removeFromInvalid('street')"
            @keyup="capitalizeFirstLetter('street')"
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row class="mb-0">
      <v-col
        lg="4"
        md="6"
        cols="12"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors, valid, validate }"
          name="Будинок"
          :rules="`alphaNum|isInvalid:${invalidFields.building}|max:${maxNumOfCharacters}`"
        >
          <v-text-field
            v-model="building"
            dense
            filled
            rounded
            outlined
            label="Будинок"
            color="text"
            item-color="text"
            :error-messages="errors"
            :menu-props="{ offsetY: false }"
            :error="!!invalidFields.building"
            :success="valid && !!building && !invalidFields.building"
            @input="matchInputModels(); validate($event); removeFromInvalid('building');
                    buildingToUpperCase();"
          />
        </validation-provider>
      </v-col>

      <v-col
        lg="4"
        md="6"
        cols="12"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors, valid, validate }"
          name="Корпус"
          :rules=
            "`alphaSpaceNums|isInvalid:${invalidFields.building_add}|max:${maxNumOfCharacters}`"
        >
          <v-text-field
            v-model="buildingAdd"
            dense
            filled
            rounded
            outlined
            label="Корпус"
            color="text"
            item-color="text"
            :error-messages="errors"
            :menu-props="{ offsetY: false }"
            :error="!!invalidFields.building_add"
            :success="valid && !!buildingAdd && !invalidFields.building_add"
            @input="matchInputModels(); validate($event); removeFromInvalid('buildingAdd')"
          />
        </validation-provider>
      </v-col>

      <v-col
        lg="4"
        cols="12"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors, valid, validate }"
          name="Квартира"
          :rules="`alphaSpaceNums|max:${maxNumOfCharacters}`"
        >
          <v-text-field
            v-model="flat"
            dense
            filled
            rounded
            outlined
            label="Квартира"
            color="text"
            item-color="text"
            :error-messages="errors"
            :menu-props="{ offsetY: false }"
            :success="valid && flat !== ''"
            @input="matchInputModels(); validate($event); removeFromInvalid('flat')"
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row
      v-if="addressType === 'registration'"
      class="checkbox-wrapper mt-0 py-2 flex-nowrap"
    >
      <v-checkbox
        id="registration"
        v-model="addressesMatch"
        color="success"
        class="pl-4"
        :dark="$vuetify.theme.dark"
        @change="matchInputModels"
      />
      <label
        class="ma-0 pt-1 text--text body-1 cursor-pointer"
        for="registration"
      >
        Адреса реєстрації співпадає з адресою проживання
      </label>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import capitalizeFirstLetterMixin from '@/mixins/capitalizeFirstLetterMixin';

export default {
  name: 'AddressType',
  mixins: [capitalizeFirstLetterMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['addressTypeFromParent', 'currentScope'],
  data() {
    return {
      maxNumOfCharacters: 10,
      maxNumOfCharactersForStreet: 40,
      addressType: this.addressTypeFromParent,
    };
  },
  computed: {
    ...mapGetters([
      'referenceBooks',
    ]),
    ...mapState([
      'defaultDataForSelect',
    ]),
    isAccommodation() {
      return this.addressType === 'accommodation';
    },
    invalidFields() {
      return this.$store.state.cashForm.address[this.addressType].invalid_fields;
    },
    addressesMatch: {
      get() {
        return this.$store.state.cashForm.address.addresses_match;
      },

      set(value) {
        this.updateState('addresses_match', value, false);
      },
    },
    regionId: {
      get() {
        return this.$store.state.cashForm.address[this.addressType].region_id;
      },
      set(value) {
        this.updateState('region_id', value);
      },
    },
    district: {
      get() {
        return this.$store.state.cashForm.address[this.addressType].district;
      },
      set(value) {
        this.updateState('district', value);
      },
    },
    localityTypeId: {
      get() {
        return this.$store.state.cashForm.address[this.addressType].locality_type_id;
      },
      set(value) {
        this.updateState('locality_type_id', value);
      },
    },
    locality: {
      get() {
        return this.$store.state.cashForm.address[this.addressType].locality;
      },
      set(value) {
        this.updateState('locality', value);
      },
    },
    streetTypeId: {
      get() {
        return this.$store.state.cashForm.address[this.addressType].street_type_id;
      },
      set(value) {
        this.updateState('street_type_id', value);
      },
    },
    street: {
      get() {
        return this.$store.state.cashForm.address[this.addressType].street;
      },
      set(value) {
        this.updateState('street', value);
      },
    },
    building: {
      get() {
        return this.$store.state.cashForm.address[this.addressType].building;
      },
      set(value) {
        this.updateState('building', value);
      },
    },
    buildingAdd: {
      get() {
        return this.$store.state.cashForm.address[this.addressType].building_add;
      },
      set(value) {
        this.updateState('building_add', value);
      },
    },
    flat: {
      get() {
        return this.$store.state.cashForm.address[this.addressType].flat;
      },
      set(value) {
        this.updateState('flat', value);
      },
    },
  },
  mounted() {
    this.$emit('onFormCreated', this.invalidFields);
  },
  methods: {
    ...mapActions('cashForm', [
      'removeFromInvalidFields',
    ]),
    buildingToUpperCase() {
      this.building = this.building.toUpperCase();
    },
    updateState(attribute, value, haveChild = true) {
      this.$store.commit('cashForm/UPDATE_FORM_DATA', {
        parentObjName: this.currentScope,
        childObjName: haveChild ? this.addressType : null,
        attribute,
        value,
      });
    },
    removeFromInvalid(propName) {
      const condition = !!this.invalidFields[propName];
      this.removeFromInvalidFields({
        parentObjName: this.currentScope,
        childObjName: this.addressType,
        propName,
        condition,
      });
    },
    setStore(attribute, value) {
      this.$store.commit('cashForm/UPDATE_FORM_DATA', {
        parentObjName: this.currentScope,
        childObjName: 'accommodation',
        attribute,
        value,
      });
    },
    matchInputModels() {
      if (this.addressesMatch) {
        const { registration } = this.$store.state.cashForm.address;

        Object.keys(registration).forEach((key) => {
          this.setStore(key, registration[key]);
        });
      }
    },
  },
};
</script>

<style lang="scss">
.checkbox-wrapper {

.v-input {
  margin: 0 !important;
}
}
</style>
