<template>
  <div
    class="cash-form mb-14"
  >
    <notification
      v-if="errorNotification && isAuthenticated"
      type="error"
    >
      {{ errorMessage }}
    </notification>

    <notification
      v-if="successNotification"
      type="success"
    >
      Ваша заявка відправлена на розгляд!
    </notification>
    <v-container v-if="isNewCreditAvailable">
      <v-row>
        <v-col class="px-0">
          <v-scale-transition
            mode="out-in"
          >
            <v-card
              v-if="isAuthenticated"
              outlined
              rounded="xl"
              class="mt-12"
              elevation="12"
              color="background lighten-1"
            >
              <v-card-title
                class="text-uppercase px-0 justify-center justify-sm-start pt-10"
              >
                <stepper class="col-12 px-5 px-sm-0" />
              </v-card-title>
              <v-card-text
                class="px-0 px-sm-4"
              >
                <v-row
                  align="center"
                  align-content="center"
                  justify="center"
                >
                  <v-col
                    cols="11"
                  >
                    <v-hover
                      v-slot="{ hover }"
                    >
                      <v-card
                        rounded="xl"
                        :elevation="hover ? 0 : 0"
                        class="my-5 position-relative"
                        color="background lighten-1"
                      >
                        <div
                          v-if="orderHasBeenCompleted"
                          class="overlay position-absolute"
                        />

                        <v-card-text
                          class="px-2 px-sm-4"
                        >
                          <personal-data
                            ref="formPersonalData"
                            class="mt-5"
                          />
                          <document-data
                            ref="formDocumentData"
                            class="mt-15"
                          />
                          <address-info
                            ref="formAddressInfo"
                            class="mt-10"
                          />
                          <general-info
                            ref="formGeneralInfo"
                            class="mt-10"
                          />
                          <contact-person
                            ref="formContactPerson"
                            class="mt-15"
                          />
                          <v-row
                            no-gutters
                            class="justify-center position-relative align-center buttons"
                          >
                            <block-preloader
                              v-if="blockPreloaderIsVisible"
                            />
                            <buttons-block
                              :class="{ 'visibility-hidden': blockPreloaderIsVisible }"
                              @continueButtonClick="submitForm"
                              @clearButtonClick="startFormCleaning"
                            />
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-scale-transition>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <CustomNotification
        class="notificationStyle"
        title="Послуга тимчасово недоступна :( "
        :button-show-toggle="true"
        button-name="повернутися на головну"
        path-for-redirect="/"
      />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Stepper from '@/components/common/Stepper';
import AddressInfo from '@/components/cash-form-items/address-form';
import ContactPerson from '@/components/cash-form-items/contact-person-form';
import PersonalData from '@/components/cash-form-items/PersonalData';
import DocumentData from '@/components/cash-form-items/document-data-form';
import GeneralInfo from '@/components/cash-form-items/general-info-form';
import Notification from '@/components/common/Notification';
import ButtonsBlock from '@/components/common/ButtonsBlock';
import blockPreloaderMixin from '@/mixins/blockPreloaderMixin';
import errorNotificationMixin from '@/mixins/errorNotificationMixin';
import workWithProductsMixin from '@/mixins/getProductByCode';
import successNotificationMixin from '@/mixins/successNotificationMixin';
import BlockPreloader from '@/components/common/BlockPreloader';
import RouteNamesService from '@/services/route-names-service';
import pageLoaderMixin from '../mixins/pageLoaderMixin';
import OrderStatusService from '../services/order-status-service';
// eslint-disable-next-line import/no-cycle
import ActiveCashOnlineOrderService from '../services/active-cash-online-order-service';
// eslint-disable-next-line import/extensions
import CustomNotification from '../components/common/CustomNotification.vue';

export default {
  name: 'CashForm',
  title: 'Cash на картку!',
  components: {
    BlockPreloader,
    Stepper,
    AddressInfo,
    ContactPerson,
    PersonalData,
    DocumentData,
    GeneralInfo,
    Notification,
    ButtonsBlock,
    CustomNotification,
  },
  mixins: [blockPreloaderMixin, errorNotificationMixin,
    workWithProductsMixin, successNotificationMixin, pageLoaderMixin],
  data() {
    return {
      currentProductCode: 'cache_online',
      errorMessage: 'Виникла помилка. Спробуйте пізніше.',
      formIsValid: false,
      formIsEditing: false,
      orderHasBeenCompleted: false,
      boolValuesOfForm: [],
      invalidForms: {},
      scoringCheckCounter: 0,
      intervalForScoring: null,
      allowToShowDataInvalidMessage: false,
      timeoutForDataInvalid: null,
      isFillOrder: false,
    };
  },
  computed: {
    ...mapState('cashForm', [
      'invalidObjects',
      'cashFormIsEditing',
    ]),
    ...mapGetters('auth', [
      'isAuthenticated',
      'isNewCreditAvailable',
    ]),
    product: {
      get() {
        return this.$store.state.cashForm.additional_info.product;
      },
      set(value) {
        this.$store.commit('cashForm/UPDATE_FORM_DATA', {
          parentObjName: 'additional_info',
          attribute: 'product',
          value,
        });
      },
    },
    clientId: {
      get() {
        return this.$store.state.clientInfo.clientData.client_id;
      },
    },
  },
  beforeMount() {
    this.updateStep(1);
    this.getActiveOrder();
    this.getReferenceBookStore(
      `DICT_EDUCATION_LEVEL, DICT_FAMILY_STATUS, JOB_OFFICIAL, DICT_PERSON_COSTS_TYPE,
      GENDER, DICT_STREET_TYPE, LOCALITY_TYPE, REGION, CONTACT_PERSON_TYPE`,
    );
    this.isStaffToogle();
  },
  mounted() {
    this.getLastState();
    this.product = this.currentProductCode;
  },
  beforeDestroy() {
    clearInterval(this.intervalForScoring);
  },
  methods: {
    ...mapActions('cashForm', [
      'updateStep',
      'addOrderStore',
      'updateExistingOrderStore',
      'getStateStore',
      'getActiveOrderStore',
      'getLastStateStore',
    ]),
    ...mapActions('auth', [
      'getClientMainInfo',
      'isStaffToogle',
    ]),
    ...mapActions([
      'getReferenceBookStore',
    ]),
    // eslint-disable-next-line consistent-return
    async getActiveOrder() {
      try {
        const activeOrder = await ActiveCashOnlineOrderService.getOrder();
        const actionType = activeOrder && activeOrder.actionType ? activeOrder.actionType.toLowerCase() : '';

        if (!activeOrder) {
          this.hidePagePreloader();
          return false;
        }

        this.formIsEditing = true;
        const isFillOrder = actionType === OrderStatusService.fillOrder;

        if (actionType && isFillOrder === false) {
          this.orderHasBeenCompleted = true;
        }

        await this.getStateStore(this.currentProductCode);
        this.beforeFormEditing();
        this.hidePagePreloader();
        this.$refs.formDocumentData.writeDownCurrentDocument();
      } catch (error) {
        this.hidePagePreloader();
        console.error(error);
      }
    },
    async getLastState() {
      const clientId = this.clientId ? this.clientId : await this.getClientMainInfo();
      if (clientId) {
        await this.getLastStateStore();
        // eslint-disable-next-line no-unused-expressions
        this.$refs.formGeneralInfo ? this.$refs.formGeneralInfo.selectOfficialId() : null;
        const interval = setInterval(() => {
          if (this.$refs.formPersonalData) {
            // this.$refs.formPersonalData.calculateUserAge();
            clearInterval(interval);
          }
        }, 500);
      }
    },
    beforeFormEditing() {
      this.convertInvalidFields();
      this.findInvalidForms(this.$store.state.cashForm, this.invalidForms);
      this.deleteEmptyObj(this.invalidForms);
      if (!this.checkObjForEmptiness(this.invalidForms)) {
        this.indicateThatFormIsEditing();
        this.saveInvalidFormsToStore(this.invalidForms);
      }
    },
    getObjectToSend() {
      // eslint-disable-next-line camelcase
      const { document_data } = this.$store.state.cashForm;

      function getDocumentDataObj() {
        const keysToCheck = ['has_photo_25_years', 'has_photo_45_years', 'has_unique_number_in_registration_certificate'];
        const obj = {};
        Object.keys(document_data)
          .forEach((key) => {
            if (keysToCheck.includes(key)) {
              // eslint-disable-next-line no-unused-expressions
              document_data[key] !== null && document_data[key] !== '' ? obj[key] = document_data[key] : null;
            } else {
              obj[key] = document_data[key];
            }
          });
        return obj;
      }
      return {
        personal_data: this.$store.state.cashForm.personal_data,
        credit_data: this.$store.state.cashForm.credit_data,
        contact_person: this.$store.state.cashForm.contact_person,
        additional_info: this.$store.state.cashForm.additional_info,
        document_data: getDocumentDataObj(),
        address: this.$store.state.cashForm.address,
        bank_card: this.$store.state.cashForm.bank_card,
        signing_contract: this.$store.state.cashForm.signing_contract,
      };
    },
    redirectToUserCredits(time = 3000) {
      const timeout = setTimeout(() => {
        this.$router.push({ name: RouteNamesService.userCredits });
        clearTimeout(timeout);
      }, time);
    },
    checkActionType() {
      this.getActiveOrderStore()
        .then((result) => {
          this.toggleBlockPreloader(false);
          let path = '';
          const cashOnlineObj = this.getProductByCode(result, this.currentProductCode);
          const thereIsCashOnlineObj = Object.keys(cashOnlineObj).length > 0;
          if (thereIsCashOnlineObj) {
            const actionTypeIsProcessing = cashOnlineObj.actionType
              && cashOnlineObj.actionType.toLowerCase() === 'processing';

            path = actionTypeIsProcessing ? RouteNamesService.userCredits
              : RouteNamesService.linkBankCard;
          } else {
            path = RouteNamesService.userCredits;
          }

          this.$router.push({ name: path });
        })
        .catch((err) => console.error(err));
    },
    checkClientId(callback) {
      this.getClientMainInfo()
        .then((result) => {
          if (result) {
            // eslint-disable-next-line no-unused-expressions
            callback ? callback() : null;
          } else {
            this.toggleSuccessMessage();
            this.redirectToUserCredits(5000);
          }
        })
        .catch((err) => console.error(err));
    },
    checkScoring() {
      // eslint-disable-next-line no-unused-vars
      const numberOfVerificationAttempts = 7;
      const fn = () => {
        this.getStateStore(this.currentProductCode)
          .then((scoring) => {
            if (scoring) {
              this.checkActionType();
            } else if (scoring !== null && !scoring) {
              this.errorMessage = 'Сталася помилка. Зверніться в технічну підтримку';
              this.toggleErrorMessage();
              this.redirectToUserCredits();
              clearInterval(this.intervalForScoring);
            }
          })
          .catch((err) => console.log(err));
      };

      fn();
      this.intervalForScoring = setInterval(() => {
        this.scoringCheckCounter += 1;
        if (this.scoringCheckCounter < numberOfVerificationAttempts) {
          fn();
        } else {
          this.toggleBlockPreloader(false);
          this.errorMessage = 'Сталася помилка. Зверніться в технічну підтримку';
          this.toggleErrorMessage();
          this.redirectToUserCredits();
          clearInterval(this.intervalForScoring);
        }
      }, 5000);
    },
    addNewOrder() {
      const obj = this.getObjectToSend();
      this.addOrderStore(obj)
        .then((data) => {
          if (data.success) {
            this.checkClientId(this.checkScoring);
          } else {
            if (data.errors && data.errors.nonFieldErrors[0].code === 'orderIsNotNew') {
              this.errorMessage = 'У Вас вже є активна заявка';
              this.redirectToUserCredits();
            }
            this.toggleBlockPreloader(false);
            this.errorMessage = 'Виникла помилка. Спробуйте пізніше.';
            this.toggleErrorMessage();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateExistingOrder() {
      try {
        const obj = this.getObjectToSend();
        const activeOrders = await this.getActiveOrderStore();
        const currentActiveOrder = activeOrders.find((item) => item.product === 'cache_online');

        const updateExistingOrderResult = await this.updateExistingOrderStore({
          order_id: currentActiveOrder.order_id,
          objToSend: obj,
        });
        if (updateExistingOrderResult) {
          this.checkClientId(this.checkScoring);
        } else {
          this.toggleBlockPreloader(false);
          this.errorMessage = 'Виникла помилка. Спробуйте пізніше.';
          this.toggleErrorMessage();
        }
      } catch (error) {
        console.error(error);
      }
    },
    submitForm() {
      const arrOfPromises = [];
      Object.keys(this.$refs)
        .forEach((item) => {
          if (item.startsWith('form')) {
            const observer = this.$refs[item].getObserver();
            // eslint-disable-next-line no-unused-expressions
            (Array.isArray(observer))
              ? observer.forEach((elem) => arrOfPromises.push(elem.validate()))
              : arrOfPromises.push(observer.validate());
          }
        });

      Promise.all([...arrOfPromises])
        .then((arr) => {
          const allFormsIsValid = arr.every((item) => item === true);
          if (allFormsIsValid) {
            this.toggleBlockPreloader(true);
            if (this.formIsEditing) {
              this.updateExistingOrder();
            } else {
              this.addNewOrder();
            }
          } else if (!allFormsIsValid) {
            this.errorMessage = 'Перевірте правильність заповнення даних';
            if (this.allowToShowDataInvalidMessage === false) {
              this.allowToShowDataInvalidMessage = true;
              this.toggleErrorMessage(true, 3000);
            }
            this.timeoutForDataInvalid = setTimeout(() => {
              this.allowToShowDataInvalidMessage = false;
              clearTimeout(this.timeoutForDataInvalid);
            }, 5000);
          }
        });
    },
    clearForm(formRef) {
      // eslint-disable-next-line no-unused-expressions
      formRef.clearForm ? formRef.clearForm() : null;
    },
    startFormCleaning() {
      if (!this.orderHasBeenCompleted) {
        Object.values(this.$refs)
          .forEach((item) => this.clearForm(item));
      } else {
        this.$router.push({ name: RouteNamesService.userCredits });
      }
    },
    convertInvalidFields() {
      this.$store.commit('cashForm/CONVERT_INVALID_FIELDS');
    },
    indicateThatFormIsEditing() {
      this.$store.commit('cashForm/CHANGE_BOOLEAN_STATE', {
        elementName: 'cashFormIsEditing',
        booleanValue: true,
      });
    },
    checkObjForEmptiness(obj) {
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    deleteEmptyObj(object) {
      const startCleaning = (obj) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
            if (this.checkObjForEmptiness(obj[key])) {
              // eslint-disable-next-line no-param-reassign
              delete obj[key];
            }
            this.deleteEmptyObj(obj[key]);
          }
        }
      };
      // eslint-disable-next-line no-restricted-syntax,no-unused-vars,guard-for-in
      for (const key in object) {
        // eslint-disable-next-line no-prototype-builtins
        if (object.hasOwnProperty(key)) {
          startCleaning(object);
        }
      }
    },
    // eslint-disable-next-line
    findInvalidForms(obj, whereToAdd) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
          // eslint-disable-next-line no-param-reassign
          whereToAdd[key] = {};
          this.findInvalidForms(obj[key], whereToAdd[key]);
          if (key === 'invalidFields') {
            // eslint-disable-next-line no-param-reassign
            whereToAdd[key] = obj[key];
          }
        }
      }
    },
    saveInvalidFormsToStore(obj) {
      this.$store.commit('cashForm/SAVE_INVALID_OBJECTS', {
        objToClone: obj,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(52, 52, 52, 0.5);
}

.notificationStyle {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
</style>
