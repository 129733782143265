var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"registration"},[_c('v-card-title',{staticClass:"text--text mx-n3"},[(_vm.isAccommodation)?[_vm._v(" Адреса проживання ")]:[_vm._v(" Адреса реєстрації ")]],2),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Область","rules":("required|isInvalid:" + (_vm.invalidFields.region_id))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('v-select',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","label":"Область*","color":"text","item-color":"text","items":_vm.referenceBooks('region').length > 0 ?
            _vm.referenceBooks('region') : _vm.defaultDataForSelect,"item-text":"value","item-value":"silver_id","error-messages":errors,"menu-props":{ offsetY: false },"error":!!_vm.invalidFields.region_id || (validated && _vm.regionId === 0),"success":valid && !!_vm.regionId && !_vm.invalidFields.region_id},on:{"change":function($event){_vm.matchInputModels($event); _vm.removeFromInvalid('regionId')}},model:{value:(_vm.regionId),callback:function ($$v) {_vm.regionId=$$v},expression:"regionId"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"lg":"4","md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Район","rules":("alphaDash|isInvalid:" + (_vm.invalidFields.district) + "|max:\n        " + _vm.maxNumOfCharactersForStreet)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
        var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","label":"Район","color":"text","item-color":"text","error-messages":errors,"menu-props":{ offsetY: false },"error":!!_vm.invalidFields.district,"success":valid && !!_vm.district && !_vm.invalidFields.district},on:{"input":function($event){_vm.matchInputModels(); validate($event); _vm.removeFromInvalid('district')},"keyup":function($event){return _vm.capitalizeFirstLetter('district')}},model:{value:(_vm.district),callback:function ($$v) {_vm.district=$$v},expression:"district"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"lg":"4","md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Тип населеного пункту","rules":("required|isInvalid:" + (_vm.invalidFields.locality_type_id))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
        var validate = ref.validate;
        var validated = ref.validated;
return [_c('v-select',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","label":"Тип населеного пункту*","color":"text","item-color":"text","item-text":"value","item-value":"silver_id","items":_vm.referenceBooks('locality_type').length > 0 ?
            _vm.referenceBooks('locality_type') : _vm.defaultDataForSelect,"error-messages":errors,"menu-props":{ offsetY: false },"error":!!_vm.invalidFields.locality_type_id || (validated && _vm.localityTypeId === 0),"success":valid && !!_vm.localityTypeId && !_vm.invalidFields.locality_type_id
            || _vm.referenceBooks('locality_type').length <= 0},on:{"change":function($event){_vm.matchInputModels(); validate($event); _vm.removeFromInvalid('localityTypeId')}},model:{value:(_vm.localityTypeId),callback:function ($$v) {_vm.localityTypeId=$$v},expression:"localityTypeId"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"lg":"4","md":"12","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Населений пункт","rules":("required|alphaDash:true|isInvalid:" + (_vm.invalidFields.locality) + "|max:\n        " + _vm.maxNumOfCharactersForStreet)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
        var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","label":"Населений пункт*","color":"text","item-color":"text","error-messages":errors,"menu-props":{ offsetY: false },"error":!!_vm.invalidFields.locality,"success":valid && !!_vm.locality && !_vm.invalidFields.locality},on:{"input":function($event){_vm.matchInputModels(); validate($event); _vm.removeFromInvalid('locality')},"keyup":function($event){return _vm.capitalizeFirstLetter('locality')}},model:{value:(_vm.locality),callback:function ($$v) {_vm.locality=$$v},expression:"locality"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Тип вулиці","rules":("required|isInvalid:" + (_vm.invalidFields.street_type_id))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var valid = ref.valid;
        var validated = ref.validated;
return [_c('v-select',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","label":"Тип вулиці*","color":"text","item-color":"text","item-text":"value","item-value":"silver_id","items":_vm.referenceBooks('dict_street_type').length > 0 ?
            _vm.referenceBooks('dict_street_type') : _vm.defaultDataForSelect,"error-messages":errors,"menu-props":{ offsetY: false },"error":!!_vm.invalidFields.street_type_id || (validated && _vm.streetTypeId === 0),"success":valid && !!_vm.streetTypeId && !_vm.invalidFields.street_type_id
            || _vm.referenceBooks('dict_street_type').length <= 0},on:{"change":function($event){_vm.matchInputModels($event); _vm.removeFromInvalid('streetTypeId')}},model:{value:(_vm.streetTypeId),callback:function ($$v) {_vm.streetTypeId=$$v},expression:"streetTypeId"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Назва вулиці","rules":("required|alphaSpaceNums|isInvalid:" + (_vm.invalidFields.street) + "|max:" + _vm.maxNumOfCharactersForStreet)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
          var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","label":"Назва вулиці*","color":"text","item-color":"text","error-messages":errors,"menu-props":{ offsetY: false },"error":!!_vm.invalidFields.street,"success":valid && !!_vm.street && !_vm.invalidFields.street},on:{"input":function($event){_vm.matchInputModels(); validate($event); _vm.removeFromInvalid('street')},"keyup":function($event){return _vm.capitalizeFirstLetter('street')}},model:{value:(_vm.street),callback:function ($$v) {_vm.street=$$v},expression:"street"}})]}}])})],1)],1),_c('v-row',{staticClass:"mb-0"},[_c('v-col',{staticClass:"py-0",attrs:{"lg":"4","md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Будинок","rules":("alphaNum|isInvalid:" + (_vm.invalidFields.building) + "|max:" + _vm.maxNumOfCharacters)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var valid = ref.valid;
          var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","label":"Будинок","color":"text","item-color":"text","error-messages":errors,"menu-props":{ offsetY: false },"error":!!_vm.invalidFields.building,"success":valid && !!_vm.building && !_vm.invalidFields.building},on:{"input":function($event){_vm.matchInputModels(); validate($event); _vm.removeFromInvalid('building');
                  _vm.buildingToUpperCase();}},model:{value:(_vm.building),callback:function ($$v) {_vm.building=$$v},expression:"building"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"lg":"4","md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Корпус","rules":("alphaSpaceNums|isInvalid:" + (_vm.invalidFields.building_add) + "|max:" + _vm.maxNumOfCharacters)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var valid = ref.valid;
                  var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","label":"Корпус","color":"text","item-color":"text","error-messages":errors,"menu-props":{ offsetY: false },"error":!!_vm.invalidFields.building_add,"success":valid && !!_vm.buildingAdd && !_vm.invalidFields.building_add},on:{"input":function($event){_vm.matchInputModels(); validate($event); _vm.removeFromInvalid('buildingAdd')}},model:{value:(_vm.buildingAdd),callback:function ($$v) {_vm.buildingAdd=$$v},expression:"buildingAdd"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"lg":"4","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Квартира","rules":("alphaSpaceNums|max:" + _vm.maxNumOfCharacters)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var valid = ref.valid;
                  var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","label":"Квартира","color":"text","item-color":"text","error-messages":errors,"menu-props":{ offsetY: false },"success":valid && _vm.flat !== ''},on:{"input":function($event){_vm.matchInputModels(); validate($event); _vm.removeFromInvalid('flat')}},model:{value:(_vm.flat),callback:function ($$v) {_vm.flat=$$v},expression:"flat"}})]}}])})],1)],1),(_vm.addressType === 'registration')?_c('v-row',{staticClass:"checkbox-wrapper mt-0 py-2 flex-nowrap"},[_c('v-checkbox',{staticClass:"pl-4",attrs:{"id":"registration","color":"success","dark":_vm.$vuetify.theme.dark},on:{"change":_vm.matchInputModels},model:{value:(_vm.addressesMatch),callback:function ($$v) {_vm.addressesMatch=$$v},expression:"addressesMatch"}}),_c('label',{staticClass:"ma-0 pt-1 text--text body-1 cursor-pointer",attrs:{"for":"registration"}},[_vm._v(" Адреса реєстрації співпадає з адресою проживання ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }