var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer"},[(_vm.isIdCard)?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Документ №","rules":("required|onlyNumbers|isInvalid:" + (_vm.invalidFields.doc_number) + "|min:9")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"placeholder":"000000000","dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Документ №*","error-messages":errors,"error":!!_vm.invalidFields.doc_number,"success":valid && _vm.docNumber !== '' && !_vm.invalidFields.doc_number},on:{"input":function($event){validate($event); _vm.removeFromInvalid('docNumber');}},model:{value:(_vm.docNumber),callback:function ($$v) {_vm.docNumber=$$v},expression:"docNumber"}})]}}],null,false,2153984868)})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Запис №","rules":("documentRecord|isInvalid:" + (_vm.invalidFields.doc_record) + "|min:14")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('########-#####'),expression:"'########-#####'"}],attrs:{"placeholder":"00000000-00000","dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Запис №","error-messages":errors,"error":!!_vm.invalidFields.doc_record,"success":valid && _vm.docRecord !== '' && !_vm.invalidFields.doc_record},on:{"input":function($event){validate($event); _vm.removeFromInvalid('docRecord');}},model:{value:(_vm.docRecord),callback:function ($$v) {_vm.docRecord=$$v},expression:"docRecord"}})]}}],null,false,3319259529)})],1)],1):_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"lg":"4","md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Серія паспорту","rules":("required|onlyLetters|isInvalid:" + (_vm.invalidFields.doc_series) + "|max:2")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Серія паспорту*","error-messages":errors,"error":!!_vm.invalidFields.doc_series,"success":valid && !!_vm.docSeries !== false && !_vm.invalidFields.doc_series},on:{"input":function($event){validate($event); _vm.removeFromInvalid('docSeries'); _vm.docSeriesToUpperCase()}},model:{value:(_vm.docSeries),callback:function ($$v) {_vm.docSeries=$$v},expression:"docSeries"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"lg":"4","md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Номер паспорту","rules":("required|onlyNumbers|isInvalid:" + (_vm.invalidFields.doc_number) + "|min:6|max:6")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Номер паспорту*","error-messages":errors,"error":!!_vm.invalidFields.doc_number,"success":valid && !!_vm.docNumber !== false && !_vm.invalidFields.doc_number},on:{"input":function($event){validate($event); _vm.removeFromInvalid('docNumber')}},model:{value:(_vm.docNumber),callback:function ($$v) {_vm.docNumber=$$v},expression:"docNumber"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"lg":"4","cols":"12"}},[_c('v-menu',{ref:"calendar",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Дата видачі","rules":("required|isInvalid:" + (_vm.invalidFields.doc_date))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.docDate || _vm.formatDate('componentDocDate'),"label":"Дата видачі*","prepend-inner-icon":"mdi-calendar","readonly":"","dense":"","filled":"","rounded":"","outlined":"","color":"text","item-color":"text","error-messages":errors,"error":!!_vm.invalidFields.doc_date,"success":valid && _vm.docDate !== '' && !_vm.invalidFields.doc_date}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.startDateCalendar),callback:function ($$v) {_vm.startDateCalendar=$$v},expression:"startDateCalendar"}},[_c('v-date-picker',{ref:"picker",attrs:{"color":"success","max":new Date().toISOString().substr(0, 10),"min":"1950-01-01","locale":"uk"},on:{"change":function($event){_vm.hideCalendar('startDateCalendar'); _vm.removeFromInvalid('docDate');
                     _vm.docDate = _vm.formatDate('componentDocDate')}},model:{value:(_vm.componentDocDate),callback:function ($$v) {_vm.componentDocDate=$$v},expression:"componentDocDate"}})],1)],1)],1),_c('v-row',[(_vm.isIdCard)?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('v-menu',{ref:"calendar",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                     var on = ref.on;
                     var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Дата видачі","rules":("required|isInvalid:" + (_vm.invalidFields.doc_date))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
                     var valid = ref.valid;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.docDate || _vm.formatDate('componentDocDate'),"label":"Дата видачі*","prepend-inner-icon":"mdi-calendar","readonly":"","dense":"","filled":"","rounded":"","outlined":"","color":"text","item-color":"text","error-messages":errors,"error":!!_vm.invalidFields.doc_date,"success":valid && _vm.docDate !== '' && !_vm.invalidFields.doc_date}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,291578416),model:{value:(_vm.startDateCalendar),callback:function ($$v) {_vm.startDateCalendar=$$v},expression:"startDateCalendar"}},[_c('v-date-picker',{ref:"picker",attrs:{"color":"success","max":new Date().toISOString().substr(0, 10),"min":"1950-01-01","locale":"uk"},on:{"change":function($event){_vm.hideCalendar('startDateCalendar'); _vm.removeFromInvalid('docDate');
                     _vm.docDate = _vm.formatDate('componentDocDate')}},model:{value:(_vm.componentDocDate),callback:function ($$v) {_vm.componentDocDate=$$v},expression:"componentDocDate"}})],1)],1):_vm._e(),(_vm.isIdCard)?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('v-menu',{ref:"calendar",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                     var on = ref.on;
                     var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Дійсний до","rules":("required|isInvalid:" + (_vm.invalidFields.doc_valid_by))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
                     var valid = ref.valid;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.docValidBy || _vm.formatDate('componentDocValidBy'),"label":"Дійсний до*","prepend-inner-icon":"mdi-calendar","readonly":"","dense":"","filled":"","rounded":"","outlined":"","color":"text","item-color":"text","error-messages":errors,"error":!!_vm.invalidFields.doc_valid_by,"success":valid && _vm.docValidBy !== '' && !_vm.invalidFields.doc_valid_by}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,3487124879),model:{value:(_vm.endDateCalendar),callback:function ($$v) {_vm.endDateCalendar=$$v},expression:"endDateCalendar"}},[_c('v-date-picker',{ref:"picker",attrs:{"color":"success","max":("" + _vm.endDateForIdCard),"min":"1950-01-01","locale":"uk"},on:{"change":function($event){_vm.hideCalendar('endDateCalendar'); _vm.removeFromInvalid('docValidBy');
                     _vm.docValidBy = _vm.formatDate('componentDocValidBy')}},model:{value:(_vm.componentDocValidBy),callback:function ($$v) {_vm.componentDocValidBy=$$v},expression:"componentDocValidBy"}})],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Орган, що видав","rules":_vm.docIssuedByValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
                     var valid = ref.valid;
                     var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Орган, що видав*","error-messages":errors,"error":!!_vm.invalidFields.doc_issued_by,"success":valid && _vm.docIssuedBy !== '' && !_vm.invalidFields.doc_issued_by},on:{"input":function($event){validate($event); _vm.removeFromInvalid('docIssuedBy')},"click":function($event){$event.preventDefault();return _vm.docIssuedByValidation.apply(null, arguments)}},model:{value:(_vm.docIssuedBy),callback:function ($$v) {_vm.docIssuedBy=$$v},expression:"docIssuedBy"}})]}}])})],1)],1),(!_vm.isIdCard && _vm.userAgeIsMoreThan25)?_c('validation-provider',{staticClass:"position-relative",attrs:{"name":"checkbox","rules":("checkboxRequired|isInvalid:" + (_vm.invalidFields.has_photo_25_years))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
                     var validated = ref.validated;
return [_c('div',{staticClass:"d-flex flex-nowrap pt-2"},[_c('v-checkbox',{staticClass:"mt-0 pt-0 checkbox",attrs:{"id":"user-has-photo-25-checkbox","color":"success","error-messages":errors,"error":!_vm.hasPhoto25Years && validated,"success":_vm.hasPhoto25Years && !_vm.invalidFields.has_photo_25_years && validated,"hide-details":""},model:{value:(_vm.hasPhoto25Years),callback:function ($$v) {_vm.hasPhoto25Years=$$v},expression:"hasPhoto25Years"}}),_c('label',{staticClass:"ma-0 text--text cursor-pointer",attrs:{"for":"user-has-photo-25-checkbox"}},[_vm._v(" В мене вклеєно фото в паспорті у 25 років ")])],1),(errors[0])?_c('span',{staticClass:"width-150 error-msg error--text v-messages pl-8 position-absolute text-no-wrap"},[_vm._v(" Вам необхідно вклеїти фото ")]):_vm._e()]}}],null,false,4200806155)}):_vm._e(),(!_vm.isIdCard && _vm.userAgeIsMoreThan45)?_c('validation-provider',{staticClass:"position-relative",attrs:{"name":"checkbox","rules":("checkboxRequired|isInvalid:" + (_vm.invalidFields.has_photo_45_years))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
                     var validated = ref.validated;
return [_c('div',{staticClass:"d-flex flex-nowrap pt-2"},[_c('v-checkbox',{staticClass:"mt-0 pt-0 checkbox",attrs:{"id":"user-has-photo-45-checkbox","name":"userHasPhoto45","color":"success","error-messages":errors,"error":!_vm.hasPhoto45Years && validated,"success":_vm.hasPhoto45Years && !_vm.invalidFields.has_photo_45_years && validated,"hide-details":""},model:{value:(_vm.hasPhoto45Years),callback:function ($$v) {_vm.hasPhoto45Years=$$v},expression:"hasPhoto45Years"}}),_c('label',{staticClass:"ma-0 text--text cursor-pointer",attrs:{"for":"user-has-photo-45-checkbox"}},[_vm._v(" В мене вклеєно фото в паспорті у 45 років ")])],1),(errors[0])?_c('span',{staticClass:"width-150 error-msg error--text v-messages pl-8 position-absolute text-no-wrap"},[_vm._v(" Вам необхідно вклеїти фото ")]):_vm._e()]}}],null,false,2664240122)}):_vm._e(),(_vm.isIdCard)?_c('validation-provider',{staticClass:"position-relative",attrs:{"name":"checkbox","rules":"checkboxRequired|isInvalid:$\n      {invalidFields.has_unique_number_in_registration_certificate}"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var validated = ref.validated;
return [_c('div',{staticClass:"d-flex flex-nowrap pt-2"},[_c('v-checkbox',{staticClass:"mt-0 pt-0 checkbox",attrs:{"id":"user-has-photo-45-checkbox","name":"userHasPhoto45","color":"green","error-messages":errors,"error":!_vm.hasUniqueNumberInRegistrationCertificate && validated,"success":_vm.hasUniqueNumberInRegistrationCertificate
            && !_vm.invalidFields.has_unique_number_in_registration_certificate && validated,"hide-details":""},model:{value:(_vm.hasUniqueNumberInRegistrationCertificate),callback:function ($$v) {_vm.hasUniqueNumberInRegistrationCertificate=$$v},expression:"hasUniqueNumberInRegistrationCertificate"}}),_c('label',{staticClass:"ma-0 text--text cursor-pointer",attrs:{"for":"user-has-photo-45-checkbox"}},[_vm._v(" В моїй Довідці про реєстрацію місця проживання/ Витяг вказаний Унікальний номер запису в Реєстрі ")])],1),(errors[0])?_c('span',{staticClass:"width-150 error-msg error--text v-messages pl-8 position-absolute text-no-wrap"},[_vm._v(" Вам необхідно зробити нову довідку форми 13/ Витяг ")]):_vm._e()]}}],null,false,1977362368)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }