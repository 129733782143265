<template>
  <div>
    <validation-observer ref="observer">
      <v-row
        v-if="isIdCard"
      >
        <v-col
          md="6"
          cols="12"
          class="py-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="Документ №"
            :rules="`required|onlyNumbers|isInvalid:${invalidFields.doc_number}|min:9`"
          >
            <v-text-field
              v-model="docNumber"
              v-mask="'#########'"
              placeholder="000000000"
              dense
              filled
              rounded
              outlined
              type="text"
              color="text"
              label="Документ №*"
              :error-messages="errors"
              :error="!!invalidFields.doc_number"
              :success="valid && docNumber !== '' && !invalidFields.doc_number"
              @input="validate($event); removeFromInvalid('docNumber');"
            />
          </validation-provider>
        </v-col>

        <v-col
          md="6"
          cols="12"
          class="py-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="Запис №"
            :rules="`documentRecord|isInvalid:${invalidFields.doc_record}|min:14`"
          >
            <v-text-field
              v-model="docRecord"
              v-mask="'########-#####'"
              placeholder="00000000-00000"
              dense
              filled
              rounded
              outlined
              type="text"
              color="text"
              label="Запис №"
              :error-messages="errors"
              :error="!!invalidFields.doc_record"
              :success="valid && docRecord !== '' && !invalidFields.doc_record"
              @input="validate($event); removeFromInvalid('docRecord');"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col
          lg="4"
          md="6"
          cols="12"
          class="py-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="Серія паспорту"
            :rules="`required|onlyLetters|isInvalid:${invalidFields.doc_series}|max:2`"
          >
            <v-text-field
              v-model="docSeries"
              dense
              filled
              rounded
              outlined
              type="text"
              color="text"
              label="Серія паспорту*"
              :error-messages="errors"
              :error="!!invalidFields.doc_series"
              :success="valid && !!docSeries !== false && !invalidFields.doc_series"
              @input="validate($event); removeFromInvalid('docSeries'); docSeriesToUpperCase()"
            />
          </validation-provider>
        </v-col>

        <v-col
          lg="4"
          md="6"
          cols="12"
          class="py-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="Номер паспорту"
            :rules="`required|onlyNumbers|isInvalid:${invalidFields.doc_number}|min:6|max:6`"
          >
            <v-text-field
              v-model="docNumber"
              dense
              filled
              rounded
              outlined
              type="text"
              color="text"
              label="Номер паспорту*"
              :error-messages="errors"
              :error="!!invalidFields.doc_number"
              :success="valid && !!docNumber !== false && !invalidFields.doc_number"
              @input="validate($event); removeFromInvalid('docNumber')"
            />
          </validation-provider>
        </v-col>

        <v-col
          lg="4"
          cols="12"
          class="py-0"
        >
          <v-menu
            ref="calendar"
            v-model="startDateCalendar"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Дата видачі"
                :rules="`required|isInvalid:${invalidFields.doc_date}`"
              >
                <v-text-field
                  :value="docDate || formatDate('componentDocDate')"
                  label="Дата видачі*"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  dense
                  filled
                  rounded
                  outlined
                  color="text"
                  item-color="text"
                  :error-messages="errors"
                  :error="!!invalidFields.doc_date"
                  :success="valid && docDate !== '' && !invalidFields.doc_date"
                  v-bind="attrs"
                  v-on="on"
                />
              </validation-provider>
            </template>
            <v-date-picker
              ref="picker"
              v-model="componentDocDate"
              color="success"
              :max="new Date().toISOString().substr(0, 10)"
              min="1950-01-01"
              locale="uk"
              @change="hideCalendar('startDateCalendar'); removeFromInvalid('docDate');
                       docDate = formatDate('componentDocDate')"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="isIdCard"
          md="6"
          cols="12"
          class="py-0"
        >
          <v-menu
            ref="calendar"
            v-model="startDateCalendar"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Дата видачі"
                :rules="`required|isInvalid:${invalidFields.doc_date}`"
              >
                <v-text-field
                  :value="docDate || formatDate('componentDocDate')"
                  label="Дата видачі*"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  dense
                  filled
                  rounded
                  outlined
                  color="text"
                  item-color="text"
                  :error-messages="errors"
                  :error="!!invalidFields.doc_date"
                  :success="valid && docDate !== '' && !invalidFields.doc_date"
                  v-bind="attrs"
                  v-on="on"
                />
              </validation-provider>
            </template>
            <v-date-picker
              ref="picker"
              v-model="componentDocDate"
              color="success"
              :max="new Date().toISOString().substr(0, 10)"
              min="1950-01-01"
              locale="uk"
              @change="hideCalendar('startDateCalendar'); removeFromInvalid('docDate');
                       docDate = formatDate('componentDocDate')"
            />
          </v-menu>
        </v-col>

        <v-col
          v-if="isIdCard"
          md="6"
          cols="12"
          class="py-0"
        >
          <v-menu
            ref="calendar"
            v-model="endDateCalendar"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Дійсний до"
                :rules="`required|isInvalid:${invalidFields.doc_valid_by}`"
              >
                <v-text-field
                  :value="docValidBy || formatDate('componentDocValidBy')"
                  label="Дійсний до*"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  dense
                  filled
                  rounded
                  outlined
                  color="text"
                  item-color="text"
                  :error-messages="errors"
                  :error="!!invalidFields.doc_valid_by"
                  :success="valid && docValidBy !== '' && !invalidFields.doc_valid_by"
                  v-bind="attrs"
                  v-on="on"
                />
              </validation-provider>
            </template>
            <v-date-picker
              ref="picker"
              v-model="componentDocValidBy"
              color="success"
              :max="`${endDateForIdCard}`"
              min="1950-01-01"
              locale="uk"
              @change="hideCalendar('endDateCalendar'); removeFromInvalid('docValidBy');
                       docValidBy = formatDate('componentDocValidBy')"
            />
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          class="py-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="Орган, що видав"
            :rules="docIssuedByValidation"
          >
            <v-text-field
              v-model="docIssuedBy"
              dense
              filled
              rounded
              outlined
              type="text"
              color="text"
              label="Орган, що видав*"
              :error-messages="errors"
              :error="!!invalidFields.doc_issued_by"
              :success="valid && docIssuedBy !== '' && !invalidFields.doc_issued_by"
              @input="validate($event); removeFromInvalid('docIssuedBy')"
              @click.prevent="docIssuedByValidation"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <validation-provider
        v-if="!isIdCard && userAgeIsMoreThan25"
        v-slot="{ errors, validated }"
        name="checkbox"
        :rules="`checkboxRequired|isInvalid:${invalidFields.has_photo_25_years}`"
        class="position-relative"
      >
        <div class="d-flex flex-nowrap pt-2">
          <v-checkbox
            id="user-has-photo-25-checkbox"
            v-model="hasPhoto25Years"
            class="mt-0 pt-0 checkbox"
            color="success"
            :error-messages="errors"
            :error="!hasPhoto25Years && validated"
            :success="hasPhoto25Years && !invalidFields.has_photo_25_years && validated"
            hide-details
          />
          <label
            class="ma-0 text--text cursor-pointer"
            for="user-has-photo-25-checkbox"
          >
            В мене вклеєно фото в паспорті у 25 років
          </label>
        </div>
        <span
          v-if="errors[0]"
          class="width-150 error-msg error--text v-messages pl-8 position-absolute text-no-wrap"
        >
          Вам необхідно вклеїти фото
        </span>
      </validation-provider>

      <validation-provider
        v-if="!isIdCard && userAgeIsMoreThan45"
        v-slot="{ errors, validated }"
        name="checkbox"
        :rules="`checkboxRequired|isInvalid:${invalidFields.has_photo_45_years}`"
        class="position-relative"
      >
        <div class="d-flex flex-nowrap pt-2">
          <v-checkbox
            id="user-has-photo-45-checkbox"
            v-model="hasPhoto45Years"
            name="userHasPhoto45"
            class="mt-0 pt-0 checkbox"
            color="success"
            :error-messages="errors"
            :error="!hasPhoto45Years && validated"
            :success="hasPhoto45Years && !invalidFields.has_photo_45_years && validated"
            hide-details
          />
          <label
            class="ma-0 text--text cursor-pointer"
            for="user-has-photo-45-checkbox"
          >
            В мене вклеєно фото в паспорті у 45 років
          </label>
        </div>
        <span
          v-if="errors[0]"
          class="width-150 error-msg error--text v-messages pl-8 position-absolute text-no-wrap"
        >
          Вам необхідно вклеїти фото
        </span>
      </validation-provider>

      <validation-provider
        v-if="isIdCard"
        v-slot="{ errors, validated }"
        name="checkbox"
        :rules="`checkboxRequired|isInvalid:$
        {invalidFields.has_unique_number_in_registration_certificate}`"
        class="position-relative"
      >
        <div class="d-flex flex-nowrap pt-2">
          <v-checkbox
            id="user-has-photo-45-checkbox"
            v-model="hasUniqueNumberInRegistrationCertificate"
            name="userHasPhoto45"
            class="mt-0 pt-0 checkbox"
            color="green"
            :error-messages="errors"
            :error="!hasUniqueNumberInRegistrationCertificate && validated"
            :success="hasUniqueNumberInRegistrationCertificate
              && !invalidFields.has_unique_number_in_registration_certificate && validated"
            hide-details
          />
          <label
            class="ma-0 text--text cursor-pointer"
            for="user-has-photo-45-checkbox"
          >
            В моїй Довідці про реєстрацію місця проживання/ Витяг вказаний Унікальний номер запису
            в Реєстрі
          </label>
        </div>
        <span
          v-if="errors[0]"
          class="width-150 error-msg error--text v-messages pl-8 position-absolute text-no-wrap"
        >
          Вам необхідно зробити нову довідку форми 13/ Витяг
        </span>
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import formatDateMixin from '@/mixins/formatDateMixin';

export default {
  name: 'About',
  mixins: [formatDateMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    documentType: String,
    // eslint-disable-next-line vue/require-default-prop
    currentScope: String,
  },
  data() {
    return {
      componentDocDate: '',
      componentDocValidBy: '',
      startDateCalendar: false,
      endDateCalendar: false,
    };
  },
  computed: {
    ...mapState('cashForm', [
      'currentStep',
      'formIsEditing',
    ]),
    ...mapState([
      'defaultDataForSelect',
    ]),
    ...mapGetters([
      'referenceBooks',
    ]),
    userAgeIsMoreThan25: {
      get() {
        return this.$store.state.cashForm.userAgeIsMoreThan25;
      },

      set(value) {
        this.$store.commit('cashForm/UPDATE_FORM_DATA', {
          attribute: 'userAgeIsMoreThan25',
          value,
        });
      },
    },
    userAgeIsMoreThan45: {
      get() {
        return this.$store.state.cashForm.userAgeIsMoreThan45;
      },

      set(value) {
        this.$store.commit('cashForm/UPDATE_FORM_DATA', {
          attribute: 'userAgeIsMoreThan45',
          value,
        });
      },
    },
    endDateForIdCard() {
      return new Date().getFullYear() + 20;
    },
    docIssuedByValidation() {
      if (this.isIdCard === false) {
        return `required|alphaSpaceNums:false,true|isInvalid:${this.invalidFields.doc_issued_by}|min:5|max:300`;
      }
      return `required|onlyNumbers|isInvalid:${this.invalidFields.doc_issued_by}|min:4|max:4`;
    },
    checkDocumentType() {
      let result = '';
      switch (this.documentType) {
        case 'passport':
          result = 'Паспорт';
          break;
        case 'idCard':
          result = 'Документ №';
          break;
        default:
          result = '';
          break;
      }
      return result;
    },
    invalidFields() {
      return this.$store.state.cashForm.document_data.invalid_fields;
    },
    hasUniqueNumberInRegistrationCertificate: {
      get() {
        return !!this.$store.state.cashForm.document_data
          .has_unique_number_in_registration_certificate;
      },
      set(value) {
        this.updateState('has_unique_number_in_registration_certificate', value);
      },
    },
    hasPhoto25Years: {
      get() {
        return !!this.$store.state.cashForm.document_data.has_photo_25_years;
      },
      set(value) {
        this.updateState('has_photo_25_years', value);
      },
    },
    hasPhoto45Years: {
      get() {
        return !!this.$store.state.cashForm.document_data.has_photo_45_years;
      },
      set(value) {
        this.updateState('has_photo_45_years', value);
      },
    },
    docSeries: {
      get() {
        return this.$store.state.cashForm.document_data.doc_series;
      },
      set(value) {
        this.updateState('doc_series', value);
      },
    },
    docNumber: {
      get() {
        return this.$store.state.cashForm.document_data.doc_number;
      },
      set(value) {
        this.updateState('doc_number', value);
      },
    },
    docRecord: {
      get() {
        return this.$store.state.cashForm.document_data.doc_record;
      },
      set(value) {
        this.updateState('doc_record', value);
      },
    },
    docDate: {
      get() {
        return this.$store.state.cashForm.document_data.doc_date;
      },
      set(value) {
        this.updateState('doc_date', value);
      },
    },
    docValidBy: {
      get() {
        return this.$store.state.cashForm.document_data.doc_valid_by;
      },
      set(value) {
        this.updateState('doc_valid_by', value);
      },
    },
    docIssuedBy: {
      get() {
        return this.$store.state.cashForm.document_data.doc_issued_by;
      },
      set(value) {
        this.updateState('doc_issued_by', value);
      },
    },
    isIdCard() {
      return this.$store.state.cashForm.document_data.is_id_card;
    },
  },
  methods: {
    ...mapActions('cashForm', [
      'removeFromInvalidFields',
      'updateStep',
    ]),
    docSeriesToUpperCase() {
      this.docSeries = this.docSeries.toUpperCase();
    },
    updateState(attribute, value) {
      this.$store.commit('cashForm/UPDATE_FORM_DATA', {
        parentObjName: this.currentScope,
        attribute,
        value,
      });
    },
    removeFromInvalid(propName) {
      const condition = !!this.invalidFields[propName];
      this.removeFromInvalidFields({
        parentObjName: this.currentScope,
        propName,
        condition,
      });
    },
    hideCalendar(propName) {
      this[propName] = false;
    },
    clearDate() {
      const items = ['docDate', 'docValidBy'];

      items.forEach((item) => this.updateState(item, ''));

      this.componentDocDate = '';
      this.componentDocValidBy = '';
    },
  },

};
</script>

<style scoped>

</style>
