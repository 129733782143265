<template>
  <div class="additional-info">
    <validation-observer ref="observer">
      <v-row class="mb-5">
        <v-card-title class="text--text">
          Загальна інформація
        </v-card-title>
      </v-row>
      <form>
        <v-row>
          <v-col
            md="6"
            lg="4"
            cols="12"
          >
            <validation-provider
              v-slot="{ errors, valid, validated }"
              name="Рівень освіти"
              :rules="`required|isInvalid:${invalidFields.education_id}`"
            >
              <v-select
                v-model="educationId"
                dense
                filled
                rounded
                outlined
                color="text"
                item-color="text"
                item-value="silver_id"
                item-text="value"
                :items="referenceBooks('dict_education_level').length > 0 ?
                  referenceBooks('dict_education_level') : defaultDataForSelect"
                label="Рівень освіти*"
                :menu-props="{ offsetY: false }"
                :error-messages="errors"
                :error="!!invalidFields.education_id || (validated && educationId === 0)"
                :success="valid && !!educationId && !invalidFields.education_id
                  || referenceBooks('dict_education_level').length <= 0"
                @change="removeFromInvalid('education_id')"
              />
            </validation-provider>
          </v-col>
          <v-col
            md="6"
            lg="4"
            cols="12"
          >
            <validation-provider
              v-slot="{ errors, valid, validated }"
              name="Сімейний стан"
              :rules="`required|isInvalid:${invalidFields.family_status_id}`"
            >
              <v-select
                v-model="familyStatusId"
                dense
                filled
                rounded
                outlined
                item-value="silver_id"
                item-text="value"
                :items="referenceBooks('dict_family_status').length > 0 ?
                  referenceBooks('dict_family_status') : defaultDataForSelect"
                color="text"
                item-color="text"
                label="Сімейний стан*"
                :menu-props="{ offsetY: false }"
                :error-messages="errors"
                :error="!!invalidFields.family_status_id || (validated && familyStatusId === 0)"
                :success="valid && !!familyStatusId && !invalidFields.family_status_id"
                @change="removeFromInvalid('family_status_id')"
              />
            </validation-provider>
          </v-col>
          <v-col
            class="col"
            lg="4"
            cols="12"
          >
            <validation-provider
              v-slot="{ errors, valid, validate }"
              name="ПІБ дружини(чоловіка)"
              :rules="`alphaDash:true|isInvalid:${invalidFields.spouse_full_name}`"
            >
              <v-text-field
                v-model="spouseFullName"
                dense
                filled
                rounded
                outlined
                type="text"
                color="text"
                label="ПІБ дружини(чоловіка)"
                :error-messages="errors"
                :error="!!invalidFields.spouse_full_name"
                :success="valid && spouseFullName !== '' && !invalidFields.spouse_full_name"
                @input="validate($event); removeFromInvalid('spouse_full_name')"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <validation-provider
              v-slot="{ errors, valid, validate }"
              name="Основний дохід, грн*"
              :rules="`required|max:${maxNumOfCharacters}|onlyNumbers:true|isInvalid:${
                invalidFields.basic_income}`"
            >
              <v-text-field
                v-model="basicIncome"
                dense
                filled
                rounded
                outlined
                type="text"
                color="text"
                label="Основний дохід, грн*"
                :error-messages="errors"
                :error="!!invalidFields.basic_income"
                :success="valid && basicIncome !== '' && !invalidFields.basic_income"
                @input="validate($event); removeFromInvalid('basic_income')"
              />
            </validation-provider>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <validation-provider
              v-slot="{ errors, valid, validate }"
              name="Додатковий дохід"
              :rules="`onlyNumbers:true|max:${maxNumOfCharacters}|isInvalid:${
                invalidFields.additional_income}`"
            >
              <v-text-field
                v-model="additionalIncome"
                dense
                filled
                rounded
                outlined
                type="text"
                color="text"
                label="Додатковий дохід"
                :error-messages="errors"
                :error="!!invalidFields.additional_income"
                :success="valid && additionalIncome !== '' && !invalidFields.additional_income"
                @input="validate($event); removeFromInvalid('additional_income')"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            lg="4"
            cols="12"
          >
            <validation-provider
              v-slot="{ errors, valid, validated }"
              name="Зайнятість"
              :rules="`required|isInvalid:${invalidFields.busyness}`"
            >
              <v-select
                v-model="busyness"
                dense
                filled
                rounded
                outlined
                :value="officialId"
                return-object
                color="text"
                item-color="text"
                item-value="silver_id"
                item-text="value"
                :items="referenceBooks('job_official')"
                label="Зайнятість*"
                :menu-props="{ offsetY: false }"
                :error-messages="errors"
                :error="!!invalidFields.official_id || (validated && officialId === 0)"
                :success="valid && !!busyness && !invalidFields.official_id"
                @change="writeDownOfficialId($event); removeFromInvalid('official_Id');
                         resetInputs();"
              />
            </validation-provider>
          </v-col>
          <v-col
            md="6"
            lg="4"
            cols="12"
          >
            <validation-provider
              v-slot="{ errors, valid, validate }"
              name="Регулярні витрати"
              :rules="`required|max:${maxNumOfCharacters}|onlyNumbers:true|isInvalid:${
                invalidFields.regular_expenses}`"
            >
              <v-text-field
                v-model="regularExpenses"
                dense
                filled
                rounded
                outlined
                type="text"
                color="text"
                label="Регулярні витрати*"
                :error-messages="errors"
                :error="!!invalidFields.regular_expenses"
                :success="valid && regularExpenses !== '' && !invalidFields.regular_expenses"
                @input="validate($event); removeFromInvalid('regular_expenses')"
              />
            </validation-provider>
          </v-col>
          <v-col
            class="col"
            md="6"
            lg="4"
            cols="12"
          >
            <validation-provider
              v-slot="{ errors, valid, validate }"
              name="Витрати по діючим кредитам"
              :rules="`max:${maxNumOfCharacters}|onlyNumbers:true
|isInvalid:${invalidFields.expenses_of_existing_loan}`"
            >
              <v-text-field
                v-model="expensesOfExistingLoan"
                dense
                filled
                rounded
                outlined
                type="text"
                color="text"
                label="Витрати по діючим кредитам"
                :error-messages="errors"
                :error="!!invalidFields.expenses_of_existing_loan"
                :success="valid && expensesOfExistingLoan !== ''
                  && !invalidFields.expenses_of_existing_loan"
                @input="validate($event); removeFromInvalid('expenses_of_existing_loan')"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <about-workplace
          v-if="busynessCode"
          ref="aboutWorkplace"
          :current-scope="currentScope"
          :current-busyness-code="busynessCode"
        />

        <v-row class="ma-0 py-2 flex-nowrap">
          <v-checkbox
            id="have-repaid-loans-checkbox"
            v-model="haveRepaidLoans"
            class="mt-0 pt-0 checkbox"
            color="success"
            hide-details
          />
          <label
            class="ma-0 text--text body-1 cursor-pointer"
            for="have-repaid-loans-checkbox"
          >
            Я маю погашені кредити в інших компаніях
          </label>
        </v-row>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import AboutWorkplace from '@/components/cash-form-items/general-info-form/AboutWorkplace';

export default {
  name: 'AdditionalInfo',
  components: {
    AboutWorkplace,
  },
  data() {
    return {
      busynessCode: '',
      maxNumOfCharacters: 10,
      currentScope: 'additional_info',
    };
  },
  computed: {
    ...mapState('cashForm', [
      'currentStep',
      'formIsEditing',
    ]),
    ...mapState([
      'defaultDataForSelect',
    ]),
    ...mapGetters([
      'referenceBooks',
    ]),
    invalidFields() {
      return this.$store.state.cashForm.additional_info.invalid_fields;
    },
    educationId: {
      get() {
        return this.$store.state.cashForm.additional_info.education_id;
      },
      set(value) {
        this.updateState('education_id', value);
      },
    },
    familyStatusId: {
      get() {
        return this.$store.state.cashForm.additional_info.family_status_id;
      },
      set(value) {
        this.updateState('family_status_id', value);
      },
    },
    spouseFullName: {
      get() {
        return this.$store.state.cashForm.additional_info.spouse_full_name;
      },
      set(value) {
        this.updateState('spouse_full_name', value);
      },
    },
    basicIncome: {
      get() {
        return this.$store.state.cashForm.additional_info.basic_income;
      },
      set(value) {
        this.updateState('basic_income', value);
      },
    },
    additionalIncome: {
      get() {
        return this.$store.state.cashForm.additional_info.additional_income;
      },
      set(value) {
        this.updateState('additional_income', value);
      },
    },
    officialId: {
      get() {
        return this.$store.state.cashForm.additional_info.official_id;
      },
      set(value) {
        this.updateState('official_id', value);
      },
    },
    regularExpenses: {
      get() {
        return this.$store.state.cashForm.additional_info.regular_expenses;
      },
      set(value) {
        this.updateState('regular_expenses', value);
      },
    },
    expensesOfExistingLoan: {
      get() {
        return this.$store.state.cashForm.additional_info.expenses_of_existing_loan;
      },
      set(value) {
        this.updateState('expenses_of_existing_loan', value);
      },
    },
    haveRepaidLoans: {
      get() {
        return this.$store.state.cashForm.additional_info.have_repaid_loans;
      },
      set(value) {
        this.updateState('have_repaid_loans', value);
      },
    },
    companyName: {
      get() {
        return this.$store.state.cashForm.additional_info.company_name;
      },
      set(value) {
        this.updateState('company_name', value);
      },
    },
    lastName: {
      get() {
        return this.$store.state.cashForm.personal_data.last_name;
      },
    },
    firstName: {
      get() {
        return this.$store.state.cashForm.personal_data.first_name;
      },
    },
    midName: {
      get() {
        return this.$store.state.cashForm.personal_data.mid_name;
      },
    },
    busyness: {
      get() {
        return this.$store.state.cashForm.additional_info.busyness;
      },
      set(value) {
        this.updateState('busyness', value);
      },
    },
    isFop() {
      return this.busynessCode === 'fop';
    },
  },
  beforeCreate() {
    /*   this.$store.dispatch(
      'getReferenceBookStore',
      'DICT_EDUCATION_LEVEL, DICT_FAMILY_STATUS, JOB_OFFICIAL, DICT_PERSON_COSTS_TYPE',
    ); */
  },
  beforeMount() {
    if (this.isFop) {
      this.companyName = this.generateCompanyName();
    }
  },
  mounted() {
    this.validateEditableForm();
  },
  methods: {
    ...mapActions('cashForm', [
      'removeFromInvalidFields',
    ]),
    writeDownOfficialId(obj) {
      if (obj) {
        this.busynessCode = obj.code;
        this.officialId = obj.silver_id;
      }
    },
    selectOfficialId() {
      if (this.officialId) {
        this.referenceBooks('job_official').forEach((item) => {
          if (item.silver_id === this.officialId) {
            this.writeDownOfficialId(item);
          }
        });
      }
    },
    updateState(attribute, value) {
      this.$store.commit('cashForm/UPDATE_FORM_DATA', {
        parentObjName: this.currentScope,
        attribute,
        value,
      });
    },
    generateCompanyName() {
      return `ФОП ${this.lastName[0] ? this.lastName : ''} ${this.firstName ? this.firstName : ''} ${this.midName ? this.midName : ''}`;
    },
    resetInputs() {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.aboutWorkplace ? this.$refs.aboutWorkplace.resetInputs() : null;
      if (this.isFop) {
        this.companyName = this.generateCompanyName();
      }
    },
    removeFromInvalid(propName) {
      const condition = !!this.invalidFields[propName];
      this.removeFromInvalidFields({
        parentObjName: this.currentScope,
        propName,
        condition,
      });
    },
    getObserver() {
      return this.$refs.observer;
    },
    validateEditableForm() {
      if (Object.keys(this.invalidFields).length > 0) {
        this.$refs.observer.validate();
      }
    },
    clearForm() {
      this.$store.commit('cashForm/CLEAR_FORM_DATA', {
        parentObjName: this.currentScope,
      });
      this.$refs.observer.reset();
      // eslint-disable-next-line no-unused-expressions
      this.$refs.aboutWorkplace ? this.$refs.aboutWorkplace.resetInputs() : null;
    },
    removeFromInvalidObjects() {
      this.$store.commit('cashForm/REMOVE_FROM_INVALID_OBJECTS', {
        objName: this.currentScope,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .col-12 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .additional-info {
    padding-top: 16px;
  }
</style>
