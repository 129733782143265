var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"additional-info"},[_c('validation-observer',{ref:"observer"},[_c('v-row',{staticClass:"mb-5"},[_c('v-card-title',{staticClass:"text--text"},[_vm._v(" Загальна інформація ")])],1),_c('form',[_c('v-row',[_c('v-col',{attrs:{"md":"6","lg":"4","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Рівень освіти","rules":("required|isInvalid:" + (_vm.invalidFields.education_id))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('v-select',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","color":"text","item-color":"text","item-value":"silver_id","item-text":"value","items":_vm.referenceBooks('dict_education_level').length > 0 ?
                  _vm.referenceBooks('dict_education_level') : _vm.defaultDataForSelect,"label":"Рівень освіти*","menu-props":{ offsetY: false },"error-messages":errors,"error":!!_vm.invalidFields.education_id || (validated && _vm.educationId === 0),"success":valid && !!_vm.educationId && !_vm.invalidFields.education_id
                  || _vm.referenceBooks('dict_education_level').length <= 0},on:{"change":function($event){return _vm.removeFromInvalid('education_id')}},model:{value:(_vm.educationId),callback:function ($$v) {_vm.educationId=$$v},expression:"educationId"}})]}}])})],1),_c('v-col',{attrs:{"md":"6","lg":"4","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Сімейний стан","rules":("required|isInvalid:" + (_vm.invalidFields.family_status_id))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var valid = ref.valid;
                  var validated = ref.validated;
return [_c('v-select',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","item-value":"silver_id","item-text":"value","items":_vm.referenceBooks('dict_family_status').length > 0 ?
                  _vm.referenceBooks('dict_family_status') : _vm.defaultDataForSelect,"color":"text","item-color":"text","label":"Сімейний стан*","menu-props":{ offsetY: false },"error-messages":errors,"error":!!_vm.invalidFields.family_status_id || (validated && _vm.familyStatusId === 0),"success":valid && !!_vm.familyStatusId && !_vm.invalidFields.family_status_id},on:{"change":function($event){return _vm.removeFromInvalid('family_status_id')}},model:{value:(_vm.familyStatusId),callback:function ($$v) {_vm.familyStatusId=$$v},expression:"familyStatusId"}})]}}])})],1),_c('v-col',{staticClass:"col",attrs:{"lg":"4","cols":"12"}},[_c('validation-provider',{attrs:{"name":"ПІБ дружини(чоловіка)","rules":("alphaDash:true|isInvalid:" + (_vm.invalidFields.spouse_full_name))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var valid = ref.valid;
                  var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"ПІБ дружини(чоловіка)","error-messages":errors,"error":!!_vm.invalidFields.spouse_full_name,"success":valid && _vm.spouseFullName !== '' && !_vm.invalidFields.spouse_full_name},on:{"input":function($event){validate($event); _vm.removeFromInvalid('spouse_full_name')}},model:{value:(_vm.spouseFullName),callback:function ($$v) {_vm.spouseFullName=$$v},expression:"spouseFullName"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Основний дохід, грн*","rules":("required|max:" + _vm.maxNumOfCharacters + "|onlyNumbers:true|isInvalid:" + (_vm.invalidFields.basic_income))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
                var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Основний дохід, грн*","error-messages":errors,"error":!!_vm.invalidFields.basic_income,"success":valid && _vm.basicIncome !== '' && !_vm.invalidFields.basic_income},on:{"input":function($event){validate($event); _vm.removeFromInvalid('basic_income')}},model:{value:(_vm.basicIncome),callback:function ($$v) {_vm.basicIncome=$$v},expression:"basicIncome"}})]}}])})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Додатковий дохід","rules":("onlyNumbers:true|max:" + _vm.maxNumOfCharacters + "|isInvalid:" + (_vm.invalidFields.additional_income))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
                var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Додатковий дохід","error-messages":errors,"error":!!_vm.invalidFields.additional_income,"success":valid && _vm.additionalIncome !== '' && !_vm.invalidFields.additional_income},on:{"input":function($event){validate($event); _vm.removeFromInvalid('additional_income')}},model:{value:(_vm.additionalIncome),callback:function ($$v) {_vm.additionalIncome=$$v},expression:"additionalIncome"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"4","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Зайнятість","rules":("required|isInvalid:" + (_vm.invalidFields.busyness))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
                var validated = ref.validated;
return [_c('v-select',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","value":_vm.officialId,"return-object":"","color":"text","item-color":"text","item-value":"silver_id","item-text":"value","items":_vm.referenceBooks('job_official'),"label":"Зайнятість*","menu-props":{ offsetY: false },"error-messages":errors,"error":!!_vm.invalidFields.official_id || (validated && _vm.officialId === 0),"success":valid && !!_vm.busyness && !_vm.invalidFields.official_id},on:{"change":function($event){_vm.writeDownOfficialId($event); _vm.removeFromInvalid('official_Id');
                         _vm.resetInputs();}},model:{value:(_vm.busyness),callback:function ($$v) {_vm.busyness=$$v},expression:"busyness"}})]}}])})],1),_c('v-col',{attrs:{"md":"6","lg":"4","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Регулярні витрати","rules":("required|max:" + _vm.maxNumOfCharacters + "|onlyNumbers:true|isInvalid:" + (_vm.invalidFields.regular_expenses))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var valid = ref.valid;
                var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Регулярні витрати*","error-messages":errors,"error":!!_vm.invalidFields.regular_expenses,"success":valid && _vm.regularExpenses !== '' && !_vm.invalidFields.regular_expenses},on:{"input":function($event){validate($event); _vm.removeFromInvalid('regular_expenses')}},model:{value:(_vm.regularExpenses),callback:function ($$v) {_vm.regularExpenses=$$v},expression:"regularExpenses"}})]}}])})],1),_c('v-col',{staticClass:"col",attrs:{"md":"6","lg":"4","cols":"12"}},[_c('validation-provider',{attrs:{"name":"Витрати по діючим кредитам","rules":("max:" + _vm.maxNumOfCharacters + "|onlyNumbers:true\n|isInvalid:" + (_vm.invalidFields.expenses_of_existing_loan))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
return [_c('v-text-field',{attrs:{"dense":"","filled":"","rounded":"","outlined":"","type":"text","color":"text","label":"Витрати по діючим кредитам","error-messages":errors,"error":!!_vm.invalidFields.expenses_of_existing_loan,"success":valid && _vm.expensesOfExistingLoan !== ''
                  && !_vm.invalidFields.expenses_of_existing_loan},on:{"input":function($event){validate($event); _vm.removeFromInvalid('expenses_of_existing_loan')}},model:{value:(_vm.expensesOfExistingLoan),callback:function ($$v) {_vm.expensesOfExistingLoan=$$v},expression:"expensesOfExistingLoan"}})]}}])})],1)],1),(_vm.busynessCode)?_c('about-workplace',{ref:"aboutWorkplace",attrs:{"current-scope":_vm.currentScope,"current-busyness-code":_vm.busynessCode}}):_vm._e(),_c('v-row',{staticClass:"ma-0 py-2 flex-nowrap"},[_c('v-checkbox',{staticClass:"mt-0 pt-0 checkbox",attrs:{"id":"have-repaid-loans-checkbox","color":"success","hide-details":""},model:{value:(_vm.haveRepaidLoans),callback:function ($$v) {_vm.haveRepaidLoans=$$v},expression:"haveRepaidLoans"}}),_c('label',{staticClass:"ma-0 text--text body-1 cursor-pointer",attrs:{"for":"have-repaid-loans-checkbox"}},[_vm._v(" Я маю погашені кредити в інших компаніях ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }