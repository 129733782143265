<template>
  <div class="personal-data">
    <validation-observer
      ref="observer"
    >
      <v-row
        no-gutters
        class="mb-1"
      >
        <v-card-title class="text--text pa-0">
          Персональні дані
        </v-card-title>
      </v-row>

      <v-row
        class="ma-0"
      >
        <p class="pa-0 pl-1 pl-sm-0 text--text">
          Заповніть Ваші дані українською мовою, як зазначено у паспорті громадянина України
          (ID-картці) або у посвідці на постійне проживання
        </p>
      </v-row>

      <v-row class="mt-1">
        <v-col
          lg="4"
          md="6"
          cols="12"
          class="py-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="Прізвище"
            :rules=
              "`required|min:${FMLMinLength}|max:${FMLMaxLength}|alphaDash|isInvalid:${
                invalidFields.last_name}`"
          >
            <v-text-field
              v-model="lastName"
              dense
              filled
              rounded
              outlined
              label="Прізвище*"
              color="text"
              item-color="text"
              :error-messages="errors"
              :menu-props="{ offsetY: false }"
              :error="!!invalidFields.last_name === true"
              :success="valid && lastName !== '' && !invalidFields.last_name"
              @input="validate($event); removeFromInvalid('lastName');"
              @keyup="capitalizeFirstLetter('lastName')"
            />
          </validation-provider>
        </v-col>
        <v-col
          lg="4"
          md="6"
          cols="12"
          class="py-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="Ім'я"
            :rules="`required|min:${FMLMinLength}|alphaDash|max:${FMLMaxLength}
            |isInvalid:${invalidFields.first_name}`"
          >
            <v-text-field
              v-model="firstName"
              dense
              filled
              rounded
              outlined
              label="Ім'я*"
              color="text"
              item-color="text"
              :error-messages="errors"
              :menu-props="{ offsetY: false }"
              :error="!!invalidFields.first_name"
              :success="valid && firstName !== '' && !invalidFields.first_name"
              @input="validate($event); removeFromInvalid('firstName')"
              @keyup="capitalizeFirstLetter('firstName')"
            />
          </validation-provider>
        </v-col>
        <v-col
          lg="4"
          cols="12"
          class="py-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="По батькові"
            :rules="`required|min:${FMLMinLength}|alphaDash|max:${FMLMaxLength}
            |isInvalid:${invalidFields.mid_name}`"
          >
            <v-text-field
              v-model="midName"
              dense
              filled
              rounded
              outlined
              label="По батькові*"
              color="text"
              item-color="text"
              :error-messages="errors"
              :menu-props="{ offsetY: false }"
              :error="!!invalidFields.mid_name"
              :success="valid && midName !== '' && !invalidFields.mid_name"
              @input="validate($event); removeFromInvalid('midName')"
              @keyup="capitalizeFirstLetter('midName')"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          md="6"
          cols="12"
          class="py-0"
        >
          <v-menu
            v-model="calendar"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Дата народження"
                :rules="`required|isInvalid:${invalidFields.birthday}`"
              >
                <v-text-field
                  :value="birthday || formatDate('componentBirthday')"
                  label="Дата народження*"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  dense
                  filled
                  rounded
                  outlined
                  color="text"
                  item-color="text"
                  :error-messages="errors"
                  :error="!!invalidFields.birthday"
                  :success="valid && birthday !== '' && !invalidFields.birthday"
                  v-bind="attrs"
                  v-on="on"
                />
              </validation-provider>
            </template>
            <v-date-picker
              v-model="componentBirthday"
              color="success"
              locale="uk"
              :max="new Date().toISOString().substr(0, 10)"
              @change="hideCalendar($event); removeFromInvalid('birthday');
                       birthday = formatDate('componentBirthday')"
            />
          </v-menu>
        </v-col>
        <v-col
          md="6"
          cols="12"
          class="py-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validate, validated }"
            name="Стать"
            :rules="`required|isInvalid:${invalidFields.gender_id}`"
          >
            <v-select
              v-model="genderId"
              dense
              filled
              rounded
              outlined
              label="Стать*"
              color="text"
              item-color="text"
              item-text="value"
              item-value="silver_id"
              :items="referenceBooks('gender').length > 0 ?
                referenceBooks('gender') : defaultDataForSelect"
              :error-messages="errors"
              :menu-props="{ offsetY: false }"
              :error="!!invalidFields.gender_id || (validated && genderId === 0)"
              :success="valid && genderId !== 0 && !invalidFields.gender_id"
              @change="validate($event); removeFromInvalid('genderId')"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row class="mb-0">
        <v-col
          cols="12"
          md="6"
          class="py-0"
        >
          <validation-provider
            v-slot="{ errors, valid, validate }"
            name="ІПН"
            :rules="
              `required|onlyNumbers|min:10|max:10|isInvalid:${invalidFields.inn}`
            "
          >
            <v-text-field
              v-model="inn"
              dense
              filled
              rounded
              outlined
              label="ІПН*"
              :disabled="innIsDisabled"
              color="text"
              item-color="text"
              :error-messages="errors"
              :menu-props="{ offsetY: false }"
              :error="!!invalidFields.inn"
              :success="valid && inn !== '' && !invalidFields.inn"
              @input="removeFromInvalid('inn'); validate()"
            />
          </validation-provider>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="py-0 position-relative"
        >
          <validation-provider
            ref="eMail"
            v-slot="{ errors, valid, validate }"
            name="e-mail"
            :rules="`required|email|isInvalid:${invalidFields.email}`"
          >
            <v-text-field
              v-model="email"
              dense
              filled
              rounded
              outlined
              :class="{ 'mb-5': errors.length >= 1 }"
              label="e-mail*"
              color="text"
              item-color="text"
              :error-messages="errors"
              :menu-props="{ offsetY: false }"
              :error="!!invalidFields.email"
              :success="valid && email !== '' && !invalidFields.email"
              @change="validate"
              @input="removeFromInvalid('email')"
            />
            <!--            <div
                          v-if="errors.length <= 0"
                          class="position-relative email-hint pl-3 pl-sm-6 caption text-lg-body-2"
                        >
                          На цю адресу прийде договір кредиту
                        </div>-->
          </validation-provider>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import formatDateMixin from '@/mixins/formatDateMixin';
import capitalizeFirstLetterMixin from '@/mixins/capitalizeFirstLetterMixin';

export default {
  name: 'PersonalData',
  mixins: [formatDateMixin, capitalizeFirstLetterMixin],
  data() {
    return {
      innIsDisabled: false,
      FMLMinLength: 2,
      FMLMaxLength: 40,
      calendar: false,
      currentScope: 'personal_data',
      componentBirthday: '',
    };
  },
  computed: {
    ...mapGetters([
      'referenceBooks',
    ]),
    ...mapGetters('cashForm', [
      'personal_data',
    ]),
    ...mapState('cashForm', [
      'currentStep',
      'formIsEditing',
    ]),
    ...mapState([
      'defaultDataForSelect',
    ]),
    invalidFields() {
      return this.$store.state.cashForm.personal_data.invalid_fields;
    },
    lastName: {
      get() {
        return this.$store.state.cashForm.personal_data.last_name;
      },

      set(value) {
        this.updateState('last_name', value);
      },
    },
    firstName: {
      get() {
        return this.$store.state.cashForm.personal_data.first_name;
      },

      set(value) {
        this.updateState('first_name', value);
      },
    },
    midName: {
      get() {
        return this.$store.state.cashForm.personal_data.mid_name;
      },

      set(value) {
        this.updateState('mid_name', value);
      },
    },
    clientId() {
      return this.$store.state.clientInfo.clientData.client_id;
    },
    clientInfoInn() {
      return this.$store.state.clientInfo.clientData.inn;
    },
    clientInfoLastName() {
      return this.$store.state.clientInfo.clientData.last_name;
    },
    clientInfoFirstName() {
      return this.$store.state.clientInfo.clientData.first_name;
    },
    clientInfoMiddleName() {
      return this.$store.state.clientInfo.clientData.middle_name;
    },
    birthday: {
      get() {
        return this.personal_data.birthday;
      },

      set(value) {
        this.updateState('birthday', value);
      },
    },
    genderId: {
      get() {
        return this.personal_data.gender_id;
      },

      set(value) {
        this.updateState('gender_id', value);
      },
    },
    email: {
      get() {
        return this.$store.state.cashForm.personal_data.email;
      },

      set(value) {
        this.updateState('email', value);
      },
    },
    inn: {
      get() {
        return this.$store.state.cashForm.personal_data.inn;
      },
      set(value) {
        this.updateState('inn', value);
      },
    },
  },
  beforeCreate() {
    this.$store.dispatch(
      'getReferenceBookStore',
      'GENDER',
    );
  },
  beforeMount() {
    this.getClientMainInfo()
      .then(() => {
        if (this.clientId) {
          this.firstName = this.clientInfoFirstName ? this.clientInfoFirstName : '';
          this.lastName = this.clientInfoLastName ? this.clientInfoLastName : '';
          this.midName = this.clientInfoMiddleName ? this.clientInfoMiddleName : '';
          if (this.clientInfoInn) {
            this.inn = this.clientInfoInn;
            this.innIsDisabled = true;
          }
        }
      })
      .catch((err) => console.error(err));
  },
  mounted() {
    this.validateEditableForm();
  },
  methods: {
    ...mapActions('cashForm', [
      'removeFromInvalidFields',
    ]),
    ...mapActions('clientInfo', [
      'getClientMainInfo',
    ]),
    updateState(attribute, value) {
      this.$store.commit('cashForm/UPDATE_FORM_DATA', {
        parentObjName: this.currentScope,
        attribute,
        value,
      });
    },
    removeFromInvalid(propName) {
      const condition = !!this.invalidFields[propName];
      this.removeFromInvalidFields({
        parentObjName: this.currentScope,
        propName,
        condition,
      });
    },
    getObserver() {
      return this.$refs.observer;
    },
    validateEditableForm() {
      if (Object.keys(this.invalidFields).length > 0) {
        this.$refs.observer.validate();
      }
    },
    clearForm() {
      this.$store.commit('cashForm/CLEAR_FORM_DATA', { parentObjName: this.currentScope });
      this.dateOfBirth = '';
      this.$refs.observer.reset();
    },
    hideCalendar() {
      this.calendar = false;
    },
    removeFromInvalidObjects() {
      this.$store.commit('cashForm/REMOVE_FROM_INVALID_OBJECTS', {
        objName: this.currentScope,
      });
    },
  },
};
</script>

<style lang="scss">
.col {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.email-hint {
  bottom: 25px;
}
</style>
